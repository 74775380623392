<template>
  <div
    class="IobImageTextElement"
    :style="styleObject"
    :class="classList"
    @click="handleElementClick"
  >
    <div
      :tabindex="isClickable && !isCheckable ? 0 : -1"
      class="IobImageTextElement-image focus-inside"
    >
      <div
        v-if="imgSrc === ''"
        style="background-color: #fff"
      />
      <iob-image
        v-else
        class="IobImageTextElement-image-main"
        :src="imgSrc"
        :render-type="imageRatio"
      />
      <div class="IobImageTextElement-image-veil">
        <icon-loader
          v-if="!isCheckable"
          color="#ffffff"
          size="large"
          name="Eye"
        />
      </div>
    </div>
    <div class="IobImageTextElement-content">
      <div class="IobImageTextElement-content-text">
        {{ text }}
      </div>
      <iob-checkbox
        v-if="isCheckable"
        id="checkbox"
        :checked="checked"
        type="rounded"
        @change-checkbox="($event) => emits('check', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import IobCheckbox from '../../Atoms/IobCheckbox/IobCheckbox.vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  imgSrc: {
    type: String,
    default: ''
  },
  imageRatio: {
    type: String,
    default: 'fit-to-box'
  },
  isClickable: {
    type: Boolean,
    default: false
  },
  isCheckable: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
});

const classList = computed(() => ({
  'IobImageTextElement--clickable': props.isClickable
}));

const styleObject = computed(() => ({
  height: props.imageRatio === 'fit-to-box' ? '100%' : 'auto'
}));

const emits = defineEmits(['click', 'check']);

const handleElementClick = (value) => {
  if (props.isClickable) {
    emits('click');
  }
  if (props.isCheckable) {
    emits('check', value);
  }
}

</script>

<style lang="scss" scoped>
.IobImageTextElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--imagetext-gap-image-title);
  width: 100%;

  &-image-veil {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(93, 88, 123, 0.5);
    opacity: 0;
  }

  &--clickable:hover, &--clickable:focus-within {
    outline: none;
    .IobImageTextElement-image {
      border: 1px solid var(--focus-color-focus);
        &-veil {
          opacity: 1;
        }
    }

    .IobImageTextElement-content {
      &-text {
        color: var(--imagetext-color-text-title-hover);
      }

      ::v-deep(svg *) {
        fill: var(--imagetext-color-text-title-hover);
      }
    }
  }

  &--clickable {
    cursor: pointer;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    &-text {
      color: var(--imagetext-color-text-title-default);
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.12px;
      overflow: hidden;
    }
  }
  &-image {
    min-height: var(--imagetext-sizing-image-height);
    width: 100%;
    border-radius: var(--imagetext-radius-image);
    border: var(--imagetext-sizing-border-width) solid var(--imagetext-color-border-default);
    position: relative;
    overflow: hidden;

    &-main {
      height: 100%;
      width: 100%;
    }

  }

}
</style>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-imageText.scss" />
