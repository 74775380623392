import * as Vue from 'vue';
import App from 'SRC/App.vue';
import router from 'SRC/router';
import authPlugin from 'SRC/plugins/auth';
import './assets/css/style.css';
import DesignSystemsComponents from '@iobeya/design-system';
import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import i18n from 'SRC/plugins/locales/i18n';
import { createPinia } from 'pinia';

// Global imports for design-tokens
import 'iobeya-design-tokens/scss/app/iobeya-app-form-group.scss';
import 'iobeya-design-tokens/scss/app/iobeya-list-elements.scss';
import 'iobeya-design-tokens/scss/app/iobeya-textInput.scss';

const pinia = createPinia();
const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    Vue,
    render() {
      return h(App, {
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa
      });
    }
  },
  handleInstance: (app) => {
    app
      .use(router)
      .use(i18n)
      .use(DesignSystemsComponents)
      .use(authPlugin)
      .use(pinia)
    ;
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
