const formulaServerRequestOptions = [
  null,
  true,
  false,
  {isUsersAPI: false, isActivated: false, interrupts: false}
];
import { get } from 'SRC/api/index.js';

const utils = {
  setFormulas: (data) => data.reduce((acc, curr) => Object.assign(acc, {
    [`${curr.id}`]: curr }), {}),
  constructFormulasQuery: (formulasIds) => {
    let result = [];
    formulasIds.forEach((id) => {
      result = [...result, `ids=${id}`];
    });
    return result.join('&');
  },
  extractFormulasIds: (dataTypes) => {
    let formulasIds = [];
    dataTypes.forEach((dataType) => {
      dataType.attributes
        .filter((attribute) => attribute.computedByFormula)
        .map((el) => ({ name: el.name, id: el.computedByFormula }))
        .forEach(async ({ id }) => {
          formulasIds = [...formulasIds, id];
        });
    });
    return formulasIds;
  },
  splitArrayOfChunks: (inputArray, maxSize) => {
    const outputArrayOfArrays  = [];
    for (let i = 0; i < inputArray.length; i += maxSize) {
      outputArrayOfArrays.push(inputArray.slice(i, i + maxSize));
    }
    return outputArrayOfArrays;
  },
  generateQueriesFromMatrix: (inputMatrix) => inputMatrix
    .map((arrayOfIds) => utils.constructFormulasQuery(arrayOfIds)
    ),
  formatRawResultsArray: (rawResults) => rawResults.map((el) => el.data).flat(),
  generateGetFormulasPromisesArray: (queries) => Promise.all(queries.map(async (query) => {
    const result = get(`${process.env.VUE_APP_FORMULA_API_URL}/v1/formulas?${query}`,
      ...formulaServerRequestOptions);
    return result;
  })),
  buildElementsById: (data) => Object.fromEntries(data.childrenData.map((element) => [element.id, { ...element, children: [] }])),
  createRootElementClone: (rootElement, elementsById) => {
    const rootElementClone = { ...rootElement, children: [] };
    elementsById[rootElementClone.id] = rootElementClone;
    return rootElementClone;
  },
  buildChildren: (datasetElementsWithChildren, elementsById) =>
    datasetElementsWithChildren.map((relation) => ({
      ...relation,
      children: relation.datasetElementChildrenIds
        .map((childId) => elementsById[childId])
        .filter(Boolean)
        .map((child) => ({ ...child, parentId: relation.datasetElementId }))
    }))
};

export default utils;
