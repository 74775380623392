<template>
  <tree-view
    :tree-data="options"
    :label-field="labelField"
    :status="status"
    :show-left-icon="showLeftIcon"
    :is-multiple-select="isMultipleSelect"
    :show-right-icon="showRightIcon"
    :is-editor="isEditor"
    @select-item="handleDropdownItemClick"
  />
</template>

<script setup>
import {ref, defineProps, defineEmits, watch} from 'vue';
import TreeView from './TreeView/TreeView.vue';

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  status: {
    type: Object,
    default: () => ({})
  },
  labelField: {
    type: String,
    default: 'label'
  },
  showRightIcon: {
    type: Boolean,
    default: false
  },
  showLeftIcon: {
    type: Boolean,
    default: true
  },
  isMultipleSelect: {
    type: Boolean,
    default: false
  },
  isEditor: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['dropdownElementClick']);
const status = ref(props.status);

function handleDropdownItemClick(selectedItem) {
  emit('dropdownElementClick', selectedItem);

  const { id, attributes } = selectedItem;
  status.value = {
    id,
    [props.labelField]: attributes?.[props.labelField] || '',
    colors: attributes?.colors || {}
  };
}

watch(() => props.status, (newStatus) => {
  status.value = newStatus;
}, { deep: true });
</script>
