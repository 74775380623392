import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    token: null,
    headers: null
  }),
  actions: {
    insertToken(token) {
      this.token = token;
      this.headers = new Headers({
        'Authorization': `Bearer ${token}`
      });
      this.authenticated = true;
    },
    logout() {
      this.token = null;
      this.headers = null;
      this.authenticated = false;
    }
  }
});
