<template>
  <div class="BoardTable">
    <div class="BoardTable-header">
      <IobHeaderAction
        :title="$t('boards.label')"
        :sub-title="bordsCount"
        :search-placeholder="$t('boards.search')"
        :grid-view-button="false"
        button-icon="Plus"
        :has-search-input="false"
        :sort-button="false"
        :filter-button="false"
        :filter-details="{}"
        :list-view-button="false"
        @button-clicked="handleBoardAdd"
      />
    </div>
    <div
      class="BoardTable-body"
      @scroll="handleScroll"
    >
      <IobTable
        :table-headers="boardHeaderConfig"
        :rows="formattedBoards"
        :more-options-menu="boardDetailsMenuItems"
        @on-click-table-row="(index) => handleTableRowClick(index)"
        @onDetailsActionClick="handleActionClick"
      />
    </div>
    <iob-dialog-box
      v-if="isDeletePopupVisible"
      :title="$t('board.delete.title')"
      :content="$t('board.delete.description')"
      :submit-action="$t('board.delete.button')"
      :cancel-action="$t('board.cancel')"
      @submitAction="onDeleteBoard"
      @cancelAction="isDeletePopupVisible = false"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useWorkspaceStore } from 'SRC/piniaStore/workspace/workspace';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useRouter } from 'vue-router';
import { boardHeaderConfig, BOARD_DETAILS_MENU_ITEMS } from 'SRC/globals/constants';
import { isDate, formatRelativeDate } from 'SRC/utils/utils';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const isLoading = ref(false);
const isDeletePopupVisible = ref(false);
const selectedBoardId = ref(null);
const isCreating = ref(false);
const boardDetailsMenuItems = ref(BOARD_DETAILS_MENU_ITEMS);
const workspaceStore = useWorkspaceStore();
const usersStore = useUsersStore();
const appStore = useAppStore();
const bordsCount = computed(() => workspaceStore.boards.length);
const nextBoardsQuery = computed(() => workspaceStore.nextBoardsQuery);
const levels = computed(() => appStore.levels);

const formattedBoards = computed(() => {
  const tableRows = workspaceStore.boards.map((board) => {
    const levelName = levels.value[board.level] ? levels.value[board.level].attributes['level-name'] : 'None';
    return {
      ...board,
      boardName: {
        title: board.name,
        showCover: true,
        coverImg: '',
        withPreview: true
      },
      creator: {
        name: getDisplayName(usersStore.getUserInfo(board.creator)) || ''
      },
      level: {
        name: levelName
      },
      activity: getBoardActivity(board.lastActivityDateTime),
      more: ['MoreHorizontal']
    };
  });

  return tableRows;
});

const handleBoardAdd = async () => {
  if (isCreating.value) {
    return;
  }
  isCreating.value = true;
  const createdBoard = await workspaceStore.createBoard();
  if (createdBoard) {
    router.push({
      path: `/board/${createdBoard.id}`,
      query: { openSettings: true }
    });
  }
  isCreating.value = false;
};

const handleActionClick = async ({actionId, index}) => {
  selectedBoardId.value = formattedBoards.value[index].id;
  if (actionId === 'deleteAction') {
    isDeletePopupVisible.value = true;
  }
};

const onDeleteBoard = async () => {
  const isDeleted = await workspaceStore.deleteBoard(selectedBoardId.value);
  if (isDeleted) {
    isDeletePopupVisible.value = false;
    selectedBoardId.value = null;
  }
};

const getDisplayName = (user) => {
  if (!user || !user.firstname || !user.lastname) {
    return '';
  }
  return `${user.firstname} ${user.lastname}`;
};

const handleScroll = async (event) => {
  const container = event.target;
  const hasReachedEnd = container.scrollHeight - container.scrollTop - container.clientHeight <= 10;
  if (hasReachedEnd && nextBoardsQuery.value && !isLoading.value) {
    await fetchBoards(false);
  }
};

const fetchBoards = async (resetParams = false) => {
  isLoading.value = true;
  await workspaceStore.fetchBoards(resetParams);
  isLoading.value = false;
};

const getBoardActivity = (modificationData) => {
  const formatedDate = formatRelativeDate(new Date(modificationData));
  const date = isDate(formatedDate) ? `${t('boards.on')} ${formatedDate}` : t(`date.${formatedDate}`);
  const boardStatus = t('boards.edited');
  return `${boardStatus} ${date}`;
};

onMounted(() => {
  fetchBoards(true);
});

const handleTableRowClick = (index) => {
  router.push(`/board/${formattedBoards.value[index].id}`);
};
</script>

<style lang="scss" scoped>
.BoardTable {
  display: flex;
  flex-direction: column;
  gap: var(--app-template-page-gap-main);
  height: 100%;
}

.BoardTable-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 var(--size-tokens-structure-page-padding-horizontal, 24px);
  z-index: 0;
}
</style>
