<template>
  <div class="IobEditorSummary-container">
    <div class="IobEditorSummary-top-section">
      <div class="IobEditorSummary-currentValue">
        Current value
      </div>
    </div>
    <div class="IobEditorSummary-divider">
      <iob-separator />
    </div>
    <div class="IobEditorSummary-bottom-section">
      <div class="IobEditorSummary-value">
        {{ `${currentValue}${unit}` }}
      </div>
      <div
        v-if="currentValue && computedCurrentTarget"
        class="IobEditorSummary-bottom-rightsection"
      >
        <div class="IobEditorSummary-bottom-rightsection-sentence">
          <icon-loader
            v-if="comparatorStatus === 'error'"
            name="xCircleFilled"
            color="red"
            style="margin-right: 8px;"
          />
          <icon-loader
            v-else
            name="CheckCircle-2-Filled"
            color="rgba(19 131 137 / 100%)"
            style="margin-right: 8px;"
          />
          {{ `${variation}${variation ? unit : ''} ${comparatorWording}` }}
          &nbsp;
          <span class="IobEditorSummary-target">{{ `( ${targetComparator} ${computedCurrentTarget ||
            ''}${unit})` }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { computed, defineProps, onMounted, ref, watch } from 'vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import { CurrentDateOccurence } from './CurrentDateOccurence';

const props = defineProps({
  currentValue: {
    type: String,
    default: '0',
  },
  target: {
    type: String,
    default: '0',
  },
  unit: {
    type: String,
    default: '',
  },
  targetComparator: {
    type: String,
    default: '≥',
  },
  glidePath: {
    type: Object,
    default: () => ({}),
  },
  frequency: {
    type: String,
    default: 'Monthly',
  },
});

const currentDateOccurenceGenerator = ref({});
const currentOccurence = ref('');

onMounted(() => {
  const date = new Date();
  const UTCDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getUTCDate()));
  currentDateOccurenceGenerator.value = new CurrentDateOccurence('Monthly', UTCDate);
  currentOccurence.value = currentDateOccurenceGenerator.value.generate();
});

watch(() => props.frequency, (newValue) => {
  currentDateOccurenceGenerator.value.frequency = newValue;
  currentOccurence.value = currentDateOccurenceGenerator.value.generate();
});

const computedCurrentTarget = computed(() => {
  if (currentOccurence.value === '') return '';
  return props.glidePath[currentOccurence.value];
});

const difference = computed(() => {
  const diff = props.currentValue - computedCurrentTarget.value;

  if (isStrictlyInferior.value) {
    return -1 - diff;
  }

  if (isStrictlySuperior.value) {
    return diff - 1;
  }

  if (isInferiorOrEqual.value) {
    return - diff;
  }

  return diff;
});

const variation = computed(() => {
  if (difference.value > 0) {
    return '+';
  }
  return '';
});

const isStrictlySuperior = computed(() => props.targetComparator === '>');
const isStrictlyInferior = computed(() => props.targetComparator === '<');
const isInferiorOrEqual = computed(() => props.targetComparator === '≤');

const comparatorStatus = computed(() => {
  if (difference.value >= 0) {
    return 'success';
  }
  return 'error'
});

const comparatorWording = computed(() => {
  if (difference.value !== 0) {
    return `${difference.value} vs. target`;
  }
  return 'On target';
});

</script>

<style lang="scss" src="./IobEditorSummary.scss" />