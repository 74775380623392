<template>
  <div class="border min-w-min w-min rounded-lg bg-white pt-3 pl-3 pr-3">
    <div class="flex justify-start items-center">
      <div
        :style="{ backgroundColor: color }"
        class="w-6 h-6 rounded-lg rounded-full ml-2"
      />
      <div class="ml-4 mr-4 Hex-label">
        Hex
      </div>
      <input
        id="color-input-text"
        :value="
          colorData.hexa ? colorData.hexa.substr(0, 7).toLowerCase() : ''
        "
        @input.stop="setColorDataInput"
      >
    </div>
    <div class="p-4 w-60">
      <div
        class="w-52 h-40"
        :style="{ backgroundColor: canvasColor, borderRadius: '4px' }"
      >
        <div
          class="w-full h-full"
          :style="'background-image: linear-gradient(90deg,#fff, rgb(204 154 129 / 0%));'"
        >
          <div
            ref="canvas"
            class="w-full h-full relative cursor-pointer"
            :style="'background-image: linear-gradient(0deg,#000,rgb(204 154 129 / 0%)); border-radius: 4px;'"
            @mousedown.stop="mousedownCanvas"
          >
            <div
              ref="canvasCursor"
              class="h-4 w-4 border border-gray-200 rounded-full bg-white absolute -left-2 -top-2 pointer-events-none"
              :style="'box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);'"
            />
          </div>
        </div>
      </div>
      <div class="Teint-label">
        TEINTE
      </div>
      <div class="w-52 flex my-1">
        <div>
          <div
            ref="line"
            class="h-3 relative cursor-pointer rounded"
            :style="'background-image: linear-gradient(90deg, red 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red); width: 210px;'"
            @mousedown.stop="mousedownLine"
          >
            <div
              ref="lineCursor"
              class="h-4 w-4 border border-gray-200 rounded-full bg-white absolute -left-2 pointer-events-none"
              :style="'top: -2px; box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        type="button"
        class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
          rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
          focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600
          dark:hover:text-white dark:hover:bg-gray-700"
        @click.stop="() => $emit('close-picker')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300
          font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700
          dark:focus:ring-gray-700 dark:border-gray-700"
        @click.stop="() => $emit('close-picker', color)"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import {
  componentToHex,
  parseHexa,
  calculateLineColor,
  validate,
  normalizeColorData
} from './utils';

export default {
  name: 'VueTailwindColorPicker',
  emits: ['close-picker'],
  data() {
    return {
      canvasCursor: null,
      lineCursor: null,
      draggingLineCursor: false,
      draggingCanvasCursor: false,
      dragStartColor: null,
      lineWidth: 210,
      lineLeft: 0,
      canvasWidth: 208,
      canvasLeft: 0,
      canvasTop: 0,
      canvasHeight: 0,
      percentageBlack: 0,
      percentageWhite: 0,
      colorLazy: {
        r: 255,
        g: 219,
        b: 0,
        a: 1,
        hexa: '#FFFE00FF'
      },
      colorData: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
        hexa: '#000000FF'
      },
      lineColorData: {
        r: 0,
        g: 0,
        b: 0
      }
    };
  },
  computed: {
    color() {
      return `rgba(${this.colorData.r}, ${this.colorData.g}, ${this.colorData.b}, ${this.colorData.a})`;
    },
    canvasColor() {
      return `rgba(${this.lineColorData.r}, ${this.lineColorData.g}, ${this.lineColorData.b}, 1)`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  beforeUnmount() {
    this.unregisterListeners();
  },
  methods: {
    setColorDataInput(event) {
      const input = event.target.value;
      if (input.length !== 7) {
        return;
      }
      this.colorData.r = parseInt(input.substr(1, 2), 16);
      this.colorData.g = parseInt(input.substr(3, 2), 16);
      this.colorData.b = parseInt(input.substr(5, 2), 16);
      this.colorData.a = 1;
      this.colorData.hexa = event.target.value;
      this.colorLazy.hexa = this.colorData.hexa;
    },
    mousedownCanvas(e) {
      if (e.which !== 1) {
        return;
      }
      this.registerListeners();
      this.dragStartColor = this.color;
      this.draggingCanvasCursor = true;
      this.setSizePoses();
      this.canvasCursor = this.$refs.canvasCursor;
      this.canvasCursor.style.transform = `translate(${e.offsetX}px, ${e.offsetY}px)`;
      this.percentageBlack = e.offsetY / this.canvasHeight;
      this.percentageWhite = 1 - e.offsetX / this.canvasWidth;
      this.setColorData();
      e.stopPropagation();
      e.preventDefault();
    },
    mousedownLine(e) {
      if (e.which !== 1) {
        return;
      }
      this.registerListeners();
      this.dragStartColor = this.color;
      this.draggingLineCursor = true;
      this.setSizePoses();
      this.lineCursor = this.$refs.lineCursor;
      this.lineCursor.style.transform = `translate(${e.offsetX}px, 0px)`;
      this.lineColorData = {
        ...this.lineColorData,
        ...calculateLineColor(e.offsetX, this.lineWidth)
      };
      this.setColorData();
      e.stopPropagation();
      e.preventDefault();
    },
    mouseup(e) {
      e.stopPropagation();
      if (this.draggingLineCursor || this.draggingCanvasCursor) {
        if (this.dragStartColor !== this.color) {
          this.$emit('change', this.colorData.hexa);
        }
      }
      this.draggingLineCursor = false;
      this.draggingCanvasCursor = false;
      this.unregisterListeners();
    },
    mousemove(e) {
      e.stopPropagation();
      if (this.draggingLineCursor) {
        let pos = e.pageX - this.lineLeft;
        pos = Math.min(this.lineWidth, Math.max(0, pos));
        this.lineCursor.style.transform = `translate(${pos}px, 0px)`;
        this.lineColorData = {
          ...this.lineColorData,
          ...calculateLineColor(pos, this.lineWidth)
        };
      } else if (this.draggingCanvasCursor) {
        let posX = e.pageX - this.canvasLeft;
        let posY = e.pageY - this.canvasTop;
        posX = Math.min(this.canvasWidth, Math.max(0, posX));
        posY = Math.min(this.canvasHeight, Math.max(0, posY));
        this.canvasCursor.style.transform = `translate(${posX}px, ${posY}px)`;
        this.percentageBlack = posY / this.canvasHeight;
        this.percentageWhite = 1 - posX / this.canvasWidth;
      }
      this.setColorData();
    },
    setColorData() {
      let targetVal = 255 * (1 - this.percentageBlack);
      targetVal = Math.min(255, Math.max(0, Math.round(targetVal)));
      const remainingR = targetVal - this.lineColorData.r;
      const remainingG = targetVal - this.lineColorData.g;
      const remainingB = targetVal - this.lineColorData.b;
      const rDiff = this.percentageWhite * remainingR;
      const gDiff = this.percentageWhite * remainingG;
      const bDiff = this.percentageWhite * remainingB;
      const r = this.lineColorData.r + rDiff;
      const g = this.lineColorData.g + gDiff;
      const b = this.lineColorData.b + bDiff;
      this.colorData.r = Math.min(targetVal, Math.max(0, Math.round(r)));
      this.colorData.g = Math.min(targetVal, Math.max(0, Math.round(g)));
      this.colorData.b = Math.min(targetVal, Math.max(0, Math.round(b)));
      this.colorData.a = 1;
      this.colorLazy.r = this.colorData.r;
      this.colorLazy.g = this.colorData.g;
      this.colorLazy.b = this.colorData.b;
      this.colorLazy.a = this.colorData.a;
      this.colorData.hexa = `#${componentToHex(
        this.colorData.r
      )}${componentToHex(this.colorData.g)}${componentToHex(
        this.colorData.b
      )}${componentToHex(Math.round(this.colorData.a * 255))}`;
      this.colorLazy.hexa = this.colorData.hexa;
    },
    registerListeners() {
      document.addEventListener('mouseup', this.mouseup, true);
      document.addEventListener('mousemove', this.mousemove, true);
    },
    unregisterListeners() {
      document.removeEventListener('mouseup', this.mouseup);
      document.removeEventListener('mousemove', this.mousemove);
    },
    setSizePoses() {
      const boundingRect = this.$refs.line.getBoundingClientRect();
      this.lineLeft = boundingRect.left;
      const canvBoundingRect = this.$refs.canvas.getBoundingClientRect();
      this.canvasLeft = canvBoundingRect.left;
      this.canvasTop = canvBoundingRect.top;
      this.canvasHeight = canvBoundingRect.height;
    },
    init() {
      if (this.value) {
        this.colorLazy = parseHexa(this.value);
      }
      this.colorData = validate(this.colorLazy);
      this.colorLazy.hexa = this.colorData.hexa;
      this.setSizePoses();
      this.setUICursors();
    },
    setUICursors() {
      const normalized = normalizeColorData(this.colorData);
      let sector = 0;
      let variablePerc = 0;
      if (normalized.variable === 'r') {
        if (normalized.g === 1) {
          sector = 1;
          variablePerc = 1 - this.colorData.r / 255;
        } else if (normalized.b === 1) {
          sector = 4;
          variablePerc = this.colorData.r / 255;
        }
      } else if (normalized.variable === 'b') {
        if (normalized.r === 1) {
          sector = 5;
          variablePerc = 1 - this.colorData.b / 255;
        } else if (normalized.g === 1) {
          sector = 2;
          variablePerc = this.colorData.b / 255;
        }
      } else {
        if (normalized.r === 1) {
          variablePerc = this.colorData.g / 255;
        } else if (normalized.b === 1) {
          sector = 3;
          variablePerc = 1 - this.colorData.g / 255;
        }
      }
      const sectorLength = this.lineWidth / 6;
      const variableSectorLeft = variablePerc * sectorLength;
      const lineCursorLeft = sectorLength * sector + variableSectorLeft;
      this.$refs.lineCursor.style.transform = `translate(${lineCursorLeft}px, 0px)`;
      this.lineColorData.r = Math.min(
        255,
        Math.max(0, Math.round(normalized.r * 255))
      );
      this.lineColorData.g = Math.min(
        255,
        Math.max(0, Math.round(normalized.g * 255))
      );
      this.lineColorData.b = Math.min(
        255,
        Math.max(0, Math.round(normalized.b * 255))
      );
      Array.from('rgb').forEach((col) => {
        if (this.lineColorData[col] === 0) {
          this.percentageWhite = this.colorData[col] / 255;
        } else if (this.lineColorData[col] === 255) {
          this.percentageBlack = 1 - this.colorData[col] / 255;
        }
      });
      const canvCursorX = this.canvasWidth * (1 - this.percentageWhite);
      const canvCursorY = this.canvasHeight * this.percentageBlack;
      this.$refs.canvasCursor.style.transform = `translate(${canvCursorX}px, ${canvCursorY}px)`;
    }
  }
};
</script>
<style src="./ColorPicker.css" />
