<template>
  <ul>
    <li>
      <div>
        <div class="label-field">
          <span
            v-if="item.children && item.children.length && showLeftIcon"
            class="toggle-icon"
            @click="toggleNode(item)"
          >
            <icon-loader
              :name="isNodeExpanded(item.id) ? 'ChevronUp' : 'ChevronDown'"
              color="#787391"
            />
          </span>
          <iob-checkbox
            v-if="isMultipleSelect"
            :id="item.id"
            :checked="item.checked"
            class="custom-checkbox"
            @change-checkbox="(value) => handleMultipleSelect(value, item)"
          />
          <input
            v-else
            type="radio"
            :checked="selectedItem && selectedItem.id === item.id"
            class="custom-radio"
            @click="handleSelect(item)"
          >
          <div
            v-show="showLabelField"
            class="initials"
            :style="{ color: colors.textColor, backgroundColor: colors.bgColor }"
          >
            {{ getInitials(item.attributes[labelField]) }}
          </div>
          <span class="label-text">
            {{ item.attributes[labelField] }}
          </span>
          <span
            v-if="item.children && item.children.length && showRightIcon"
            class="toggle-icon"
            @click="toggleNode(item)"
          >
            <icon-loader
              :name="isNodeExpanded(item.id) ? 'ChevronUp' : 'ChevronDown'"
              color="#787391"
            />
          </span>
        </div>
        <ul
          v-if="isNodeExpanded(item.id)"
          class="dropdown-menu"
        >
          <tree-item
            v-for="child in item.children"
            :key="child.id"
            :data-id="child.id"
            :item="child"
            :colors="child.attributes.colors"
            :label-field="labelField"
            :show-label-field="showLabelField"
            :is-multiple-select="isMultipleSelect"
            :expanded-nodes="expandedNodes"
            :show-right-icon="showRightIcon"
            :show-left-icon="showLeftIcon"
            :selected-item="selectedItem"
            @select-item="$emit('select-item', $event)"
            @select-multiple-items="$emit('select-multiple-items', $event)"
            @toggle-node="$emit('toggle-node', $event)"
          />
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import IconLoader from '../../../IconLoader/IconLoader.vue';
import { getInitials } from '../../../../utils';
import IobCheckbox from '../../../Atoms/IobCheckbox/IobCheckbox.vue';

export default {
  name: 'TreeItem',
  components: { IconLoader, IobCheckbox },
  props: {
    item: {
      type: Object,
      required: true
    },
    expandedNodes: {
      type: Array,
      required: true
    },
    colors: {
      type: Object,
      default: () => ({})
    },
    labelField: {
      type: String,
      default: ''
    },
    isMultipleSelect: {
      type: Boolean,
      default: false
    },
    showLeftIcon: {
      type: Boolean,
      default: true
    },
    showRightIcon: {
      type: Boolean,
      default: false
    },
    showLabelField: {
      type: Boolean,
      default: true
    },
    selectedItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedItems: []
    };
  },
  methods: {
    toggleNode(item) {
      this.$emit('toggle-node', item);
    },
    isNodeExpanded(nodeId) {
      return this.expandedNodes.includes(nodeId);
    },
    handleSelect(item) {
      this.$emit('select-item', item);
    },
    getInitials(item) {
      return getInitials(item);
    },
    handleMultipleSelect(value, item) {
      item.checked = value.isChecked;
      this.$emit('select-multiple-items', item);
    }
  }
};
</script>

<style lang="scss" scoped src="./TreeItem.scss" />
