import { get, post, del } from 'SRC/api/index.js';
import {CONTENT_TYPES} from 'SRC/globals/constants';

export default {
  async fetchAttachments(ids) {
    if (!ids?.length) {
      return [];
    }
    const response = await get(`/attachments?ids=${ids.join(',')}`);
    if (response) {
      const attachments = response.data;
      if (attachments?.length) {
        return attachments.map((attachment) => ({
          ...attachment,
          createdOn: new Date(attachment.createdOn).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          }).replace(',', '')
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  },
  async createUrlAttachment(url, title) {
    try {
      const formData = new FormData();
      formData.append('url', url);
      formData.append('title', title);
      formData.append('type', 'url');
      const transformBodyFn = (body) => body;

      const response = await post('/attachments', formData, true, true, false, transformBodyFn,  CONTENT_TYPES.MULTIPART_FORM_DATA);

      if (response) {
        const newAttachment = {
          ...response,
          createdOn: new Date(response.createdOn).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          }).replace(',', '')
        };
        return newAttachment;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async deleteAttachment(attachmentId) {
    try {
      await del(`/attachments/${attachmentId}`);
    } catch (error) {
      console.error(error);
    }
  }
};
