import {API_URL} from 'SRC/config/api.js';
import {useAppStore} from 'SRC/piniaStore/app/app';
import CircuitBreaker from 'opossum';
import {makeRequest} from '../utils/CircuitBreaker';
import {useAuthStore} from 'SRC/piniaStore/auth/auth';
import {CONTENT_TYPES} from 'GLOBALS/constants';

function getAuthHeader() {
  const authStore = useAuthStore();
  const token = authStore.token || '';
  return {
    'Authorization': `Bearer ${token}`
  };
}

function transformBody(body, transformBodyFn) {
  return body !== null && body !== undefined ? transformBodyFn(body) : null;
}

function constructHeaders(contentType) {
  return {
    'X-iObeya-VMS-API-Returns-Self-Url': 'relative',
    ...getAuthHeader(),
    // Allow the browser to automatically set the Content-Type header for multipart form data when uploading files,
    // including the boundary (e.g., boundary=----WebKitFormBoundary...)
    ...(contentType !== CONTENT_TYPES.MULTIPART_FORM_DATA && { 'Content-Type': contentType })
  };
}

async function handleResponse(response, toJson) {
  if (response.status === 204) {
    return true;
  }

  const contentType = response?.headers?.get('content-type');

  if (contentType?.includes(CONTENT_TYPES.EXCEL)) {
    return response.blob();
  }

  if (toJson) {
    const res = await response.json();
    if (res) {
      if (res.error || res.status >= 400) {
        throw new Exception(res.status, res.error, null, res.path);
      }
    }
    return res;
  }
  return true;
}

function fetcher(method) {
  // eslint-disable-next-line no-unused-vars
  return async (url, body = null, toJson = true, isRelative = true,
    interrupts = false, transformBodyFn = JSON.stringify,
    contentType = CONTENT_TYPES.JSON) => {
    const requestUrl = isRelative ? `${API_URL}${url}` : `${url}`;
    const headers = constructHeaders(contentType);
    try {
      const response = await fetch(requestUrl, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: transformBody(body, transformBodyFn)
      });

      return await handleResponse(response, toJson);
    } catch (exception) {
      if (interrupts) {
        const appStore = useAppStore();
        const exceptions = appStore.exceptions;
        exceptions.push(exception);
        appStore.setExceptions(exceptions);
        throw exception;
      }
      throw exception;
    }
  };
}

class Exception {
  constructor(status, type, title, detail) {
    this.status = status;
    this.type = type;
    this.title = title;
    this.detail = detail;
  }
}

const userOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
const vmsOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
export const vmsCircuitBreaker = new CircuitBreaker(fetcher('GET'), vmsOptions);
export const userCircuitBreaker = new CircuitBreaker(fetcher('GET'), userOptions);
export const get = async (url, body, toJson, isRelative, circuitBreakerInfo = {}) => {
  const {isActivated = true, interrupts, isUsersAPI} = circuitBreakerInfo;
  if (!isActivated) {
    return fetcher('GET')(url, body, toJson, isRelative, {});
  }
  if (isUsersAPI) {
    return makeRequest(userCircuitBreaker, url, body, toJson, isRelative, interrupts);
  }
  return makeRequest(vmsCircuitBreaker, url, body, toJson, isRelative, interrupts);
};

export const patch = fetcher('PATCH');
export const post = fetcher('POST');
export const put = fetcher('PUT');
export const del = fetcher('DELETE');

export default {
  get,
  patch,
  post,
  put,
  del
};
