import { defineStore } from 'pinia';
import { USERS_SERVICE_URL } from 'SRC/config/services';
import { get } from 'SRC/api/index.js';
import storageService from '../storageService';
import apiUtils from '../utils';
const { ALL_USERS_KEY } = storageService.sessionKeys;
export const saveUsers = (users) => storageService.saveToLocal(ALL_USERS_KEY, users);
export const getUsers = () => storageService.getFromLocal(ALL_USERS_KEY);
const circuitBreakerInfo = { isUsersAPI: true, isActivated: true, interrupts: false };

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    currentUser: null,
    currentUserColor: null,
    usersOnBoard: []
  }),
  getters: {
    getUserInfo(state) {
      return (userId) => state.users.find((user) => user.id === userId);
    }
  },
  actions: {
    async fetchAllUsers() {
      const users = await get(`${USERS_SERVICE_URL}/users/list`, null, true, false, circuitBreakerInfo);
      let usersData = null;
      if (users && users.data) {
        usersData = await apiUtils.getNextPage(
          users,
          users.data,
          get,
          null,
          false,
          circuitBreakerInfo
        );
        saveUsers(usersData);
      }
      usersData = usersData || getUsers();
      if (!usersData) {
        return { error: 'Error occured in fetchAllUsers' };
      }
      this.users = usersData;
      return users;
    },
    async fetchCurrentUser() {
      const user = await get(`${USERS_SERVICE_URL}/users/me`, null, true, false, circuitBreakerInfo);
      if (user) {
        this.currentUser = user;
        return user;
      }
      return { error: 'Error occured in fetchCurrentUser' };
    }
  }
});
