import { dataTypesIcons } from 'SRC/globals/constants';
import { getUserInitials } from 'SRC/utils/utils';

export const getIcon = (dataTypeName, hierarchy) => ({
  icon: dataTypesIcons[dataTypeName]?.icons[hierarchy] || 'Target',
  color: dataTypesIcons[dataTypeName]?.color || 'primary'
});

export const getUser = (users, id) => {
  const user = users.value.filter((user) => user.id === id)[0];
  if (user) {
    return {
      name: `${user.firstname} ${user.lastname}`,
      img: user.picture
    };
  }
  return { name: '', img: '' };
};

export const formatElement = ({ users, datatypes, selectedDatasetElements, attributes, typeId }) => {
  const data = {};
  const dataTypeName = datatypes.value[typeId]?.name;
  const attributesNames = datatypes.value[typeId]?.attributes.map((attr) => attr.name);
  const id = attributes['friendly-id'];
  const isChecked = selectedDatasetElements.value.includes(id);
  const rank = ['id', 'type', 'title', 'status', 'owner', 'progress'];
  rank.forEach((name) => {
    const isDisabled = !attributesNames?.includes(name);
    switch (name) {
    case 'type':
      data.TYPE = { ...getIcon(dataTypeName, 'parent'), title: attributes.type || dataTypeName, id, checked: isChecked, isDisabled: false };
      break;
    case 'id':
      data.ID = id;
      break;
    case 'title':
      data.TITLE = { title: attributes.title, isDisabled };
      break;
    case 'status':
      data.STATUS = {statusList: [{ label: attributes.status }], isDisabled };
      break;
    case 'owner':
      data.OWNER = {...getUser(users, attributes.owner), isDisabled};
      break;
    case 'progress':
      data.PROGRESSION = {
        progress: isNaN(attributes.progress) ? 0 : attributes.progress,
        color: 'secondary', isDisabled
      };
      break;
    }
  });
  return data;
};

export const mergeAttributes = (attributes, attributesPanel) => {
  const mergedAttributes = attributes.reduce((acc, attr) => {
    const attributePanel = attributesPanel.find((filterAttr) => filterAttr.name === attr.name);
    if (attributePanel) {
      acc[attr.name] = { ...attributePanel, ...attr };
    } else {
      acc[attr.name] = { ...attr };
    }
    return acc;
  }, {});
  return mergedAttributes;
};

export const filterAttributes = (attributes, id) => {
  const attributesTypes = ['string', 'color', 'int', 'glidepath_values', 'timestamped_indicator'];
  let attributesNames =  ['targetValueComparator', 'contributors', 'driver', 'glidepathFrequency', 'measurement'];
  if (!id) {
    attributes.unshift({
      name: 'family-type',
      friendlyName: 'Family',
      type: 'family'
    });
    attributesNames = ['type', 'status', ...attributesNames];
  }
  const filteredData = attributes.filter((attr) => (!attributesTypes.some((type) => type === attr.type) || (attr.enum)))
    .filter((attr) => (!attributesNames.some((type) => type === attr.name)));
  return filteredData;
};

export const filterDataOptionsByType = (attributes, list, id) => {
  const filteredAttributes = filterAttributes(attributes, id);
  return filteredAttributes.map((attr) => {
    let options = [] ; let maxDisplayedItems = 5; let treeValues = null;
    if (attr.type === 'user' || attr.type === 'list') {
      options = list.allUsers.value.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        id: user.id,
        showIcon: false,
        name: attr.name,
        avatar: {
          picture: user.picture,
          size: 'default',
          color: '#8AC3C5',
          altText: getUserInitials(user.firstname, user.lastname)
        },
        type: 'member'
      })) || [];
      maxDisplayedItems = 2;
    } else if (attr.type === 'level') {
      options = list.allLevels.value;
      treeValues = {labelField: 'level-name', status: 'Level test', showLabelField: true};
    } else if (attr.enum && attr.enum.length) {
      options = attr.enum.map((item) => ({
        text: item.value,
        name: attr.name,
        iconName: '',
        type: attr.componentType === 'badge' ? 'badge' : 'menu',
        state: 'default',
        iconSize: 'default'
      })) || [];
    } else if (attr.type === 'family') {
      options = list.dataTypes.value.map((item) => ({
        text: item.name,
        id: item.id,
        name: attr.name,
        iconName: '',
        type: 'menu',
        state: 'default',
        iconSize: 'default',
        withID: true
      })) || [];
    }
    const {name, componentType, friendlyName} = attr;
    return {name, componentType, label: friendlyName, options, maxDisplayedItems, treeValues} ;
  });
};
