<template>
  <div
    :class="classList"
    :tabindex="isClickable && !disabled ? 0 : -1"
    :style="iconShapeStyle"
  >
    <icon-loader
      class="IobIconShape-icon"
      :name="iconName"
      :size="getIconSize"
      :color="iconColor"
      :custom-dimension="customIconDimension"
    />
    <iob-image
      v-if="kanjiImageUrl"
      render-type="1:1"
      :src="kanjiImageUrl"
      class="IobIconShape-kanji-image"
    />
  </div>
</template>
   
<script setup>
import { defineProps, computed } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobImage from '../IobImage/IobImage.vue';

const props = defineProps({
  iconName: {
    type: String,
    default: ''
  },
  iconColor: {
    type: String,
    default: undefined
  },
  size: {
    type: String,
    default: 'default'
  },
  type: {
    type: String,
    default: 'square'
  },
  color: {
    type: String,
    default: 'primary'
  },
  isClickable: {
    type: Boolean,
    default: true
  },
  kanjiImage: {
    type: String,
    default: '',
    validator: (value) => {
      return ['01', '02', '03', '04'].includes(value);
    }
  },
  disabled: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  },
  customDimension: {
    type: String,
    default: ''
  },
  customIconDimension: {
    type: String,
    default: ''
  },
  applyColor: {
    type: Boolean,
    default: true
  },
  customBackgroundColor: {
    type: String,
    default: ''
  }
});

const iconShapeStyle = computed(() => {
  let style = ''
  if (props.customDimension) {
    style = `width: ${props.customDimension}px; height: ${props.customDimension}px;`;
  }
  if (props.customBackgroundColor) {
    style += `background-color: ${props.customBackgroundColor}`
  }
  return style;
});

const typeClass = computed(()=> {
  return props.type === 'square' ? `IobIconShape-square-${props.size}` : 'IobIconShape-circle';
});

const getIconSize = computed(()=> {
  switch (props.size) {
  case 'xsmall':
  case 'small':
    return 'xsmall';
  case 'default':
  case 'large':
    return 'small';
  case 'xl':
    return 'default';
  case '2xl':
    return 'large';
  case '3xl':
    return '2xl';
  case '4xl':
    return '3xl';
  default:
    return 'default';
  }
});
const classList = computed(()=> {
  let list = [
    'IobIconShape',
    `IobIconShape-${props.size}`,
    typeClass.value,
    `IobIconShape-${props.color}`,
    { 
      'focus-outside': !props.disabled,
      'IobIconShape--clickable': props.isClickable,
      'IobIconShape--disabled': props.disabled,
      'IobIconShape--selected': props.selected
    }
  ];
  if (props.applyColor) return [...list, `IobIconShape-${props.color}`]
  return list; 
});

const kanjiImageUrl = computed(() => {
  const theme = document.documentElement.classList.contains('theme-dark') ? 'dark' : 'light';
  const hasKanjiImage = ['01', '02', '03', '04'].includes(props.kanjiImage);
  return hasKanjiImage? require(`../../../assets/img/kanji-${props.kanjiImage}-${theme}.png`) : '';
});
     
</script>
   
   <style lang="scss" src="./IobIconShape.scss" scoped />
   