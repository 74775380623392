import { defineStore } from 'pinia';
import { get, post, put, del } from 'SRC/api';
import { PAGE_SIZE } from 'GLOBALS/constants';
import { useEditorStore } from '../../editor/editor';
import { useAppStore } from '../../app/app';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useDataStore } from 'SRC/piniaStore/data/data';
import apiUtils from 'SRC/piniaStore/utils';
import { RELATION_TYPES } from 'SRC/globals/constants';

export async function getAllItemsFromAllPages(paginatedUrl) {
  const allItems = [];
  let url = paginatedUrl;
  while (url !== undefined) {
    const itemPage = await get(url);
    allItems.push(...itemPage.data);
    url = itemPage.next;
  }
  return allItems;
}

export const useHierarchyStore = defineStore('hierarchy', {
  state: () => ({
    parent: null,
    children: [],
    createdDependencies: [],
    dependencies: [],
    eligibleChildren: [],
    eligibleParents: [],
    eligibleChildDataTypes: [],
    eligibleParentDataTypes: [],
    eligibleChildrenInitialCount: 0,
    eligibleDependencies: [],
    relationTypes: []
  }),
  actions: {
    async fetchRelationTypes() {
      const response = await get('/relation-types');
      if (response && response.data) {
        this.relationTypes = response.data;
      }
    },
    async getRelationFromDatasetElementId(id) {
      return await get(`/hierarchical-relations/${id}`);
    },
    async getHierarchicalRelations(hierarchicalRelationIds) {
      try {
        return await Promise.all(hierarchicalRelationIds.map((id) => this.getRelationFromDatasetElementId(id)));
      } catch (error) {
        return;
      }
    },
    async fetchHierarchicalRelations() {
      const editorStore = useEditorStore();
      const datasetElementId = editorStore.datasetElementId;
      const hierarchicalRelationsData = await get(`/hierarchical-relations?datasetElementIds=${datasetElementId}`);

      if (hierarchicalRelationsData?.data?.length) {
        const { datasetElementParentId, datasetElementChildrenIds, hierarchicalRelationIds } = hierarchicalRelationsData.data[0];
        const hierarchicalRelations = await this.getHierarchicalRelations(hierarchicalRelationIds);
        if (!hierarchicalRelations) {
          return;
        }
        if (datasetElementParentId) {
          const parentDatasetElement = await get(`/dataset-elements/${datasetElementParentId}`);

          if (parentDatasetElement) {
            const parent = editorStore.getRelationObject(parentDatasetElement);
            const parentId = this.getRelationFromDatasetElementIds(hierarchicalRelations, datasetElementParentId, datasetElementId)?.id;
            const hierarchicalRelationId = Object.values(hierarchicalRelationIds).find((id) => id === parentId);

            this.parent = { ...parent, hierarchicalRelationId };
          }
        }

        if (datasetElementChildrenIds?.length) {
          const query = datasetElementChildrenIds.map((id) => `ids=${id}`).join('&');
          const childrenDatasetElements = await getAllItemsFromAllPages(`/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`);
          const children = childrenDatasetElements.map((childDatasetElement) => editorStore.getRelationObject(childDatasetElement));
          const formattedChildren = children.map((element) => {
            const childId = this.getRelationFromDatasetElementIds(hierarchicalRelations, datasetElementId, element.id)?.id;
            const hierarchicalRelationId = Object.values(hierarchicalRelationIds).find((id) => id === childId);
            return { ...element, hierarchicalRelationId };
          });
          this.children = formattedChildren;
        }
      }
    },
    async fetchEligibleDataTypes() {
      const editorStore = useEditorStore();
      const appStore = useAppStore();
      const { dataType } = editorStore;
      const { datatypes } = appStore;
      if (!(dataType && dataType.id)) {
        return;
      }
      const eligibleDataTypes = await get(
        `/hierarchical-relation-definitions?dataTypeId=${dataType.id}`
      );

      if (eligibleDataTypes && eligibleDataTypes.parentDataTypeIds) {
        const eligibleParentDataTypesData = eligibleDataTypes.parentDataTypeIds;
        this.eligibleParentDataTypes = eligibleParentDataTypesData;
      }

      if (eligibleDataTypes && eligibleDataTypes.childDataTypeIds) {
        const childDataTypeIds = eligibleDataTypes.childDataTypeIds.filter(
          (id) => datatypes[id]
        );
        const eligibleChildDataTypesData = childDataTypeIds.map(
          (childDataTypeId) => ({
            id: childDataTypeId,
            name: datatypes[childDataTypeId].name
          })
        );
        this.eligibleChildDataTypes = eligibleChildDataTypesData;
      }
    },
    async fetchEligibleElementsForRelations({type, attributes, searchQuery, dataTypeFilters}) {
      const editorStore = useEditorStore();
      const id = editorStore.datasetElementId;
      const RELATION_URLS = {
        [RELATION_TYPES[0]]: `/dataset-elements/${id}/eligible-parents/search-requests`,
        [RELATION_TYPES[1]]: `/dataset-elements/${id}/eligible-children/search-requests`,
        [RELATION_TYPES[2]]: '/dataset-elements/search-requests'
      };

      let requestUrl = RELATION_URLS[type];
      if (!requestUrl) {
        return;
      }
      requestUrl += `?page=1&size=${PAGE_SIZE}${searchQuery ? `&${searchQuery}` : ''}`;
      const formattedAttributes = { attributes: attributes || {} };
      const body = {
        ...formattedAttributes,
        ...(dataTypeFilters && { dataTypeFilters })

      };

      const eligibleHierarchyElements = await post(requestUrl, body);
      const apiResponseData = eligibleHierarchyElements?.data ?
        await apiUtils.getNextPage(eligibleHierarchyElements, eligibleHierarchyElements.data, post, body, false) : [];

      const relationObject = (apiResponseData || [])
        .map((element) => editorStore.getRelationObject(element))
        .filter((element) => element.id !== id);

      if (type === RELATION_TYPES[0]) {
        this.eligibleParents = relationObject;
        return;
      }
      if (type === RELATION_TYPES[1]) {
        this.eligibleChildren = relationObject;
        return;
      }

      this.eligibleDependencies = relationObject.filter((eligibleDependency) =>
        !this.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
      );
    },
    async createRelationsFromExistingElements({ relation, eligibleDatasetElementId }) {
      const editorStore = useEditorStore();
      const id = editorStore.datasetElementId;
      const requestBody = apiUtils.createRequestBodyForNewRelation(relation, eligibleDatasetElementId, id);
      const creations = await post('/hierarchical-relations', requestBody);

      if (!creations?.data?.length) {
        return false; // No relations were created
      }

      const createdHierarchicalRelation = creations.data.find(({ status }) => status === 200);
      let eligibleDatasetElement = await get(`/dataset-elements/${eligibleDatasetElementId}`);
      eligibleDatasetElement = editorStore.getRelationObject(eligibleDatasetElement);
      const formattedHierarchicalRelation = {
        ...eligibleDatasetElement,
        hierarchicalRelationId: createdHierarchicalRelation.id
      };

      return relation === 'parent'
        ? this.handleParentRelation(formattedHierarchicalRelation, eligibleDatasetElementId)
        : this.handleChildRelation(formattedHierarchicalRelation, eligibleDatasetElementId, editorStore);
    },
    handleParentRelation(formattedHierarchicalRelation, eligibleDatasetElementId) {
      this.eligibleParents = this.eligibleParents.filter((parent) => parent.id !== eligibleDatasetElementId);
      this.parent = formattedHierarchicalRelation;
      return true; // Parent was created
    },
    async handleChildRelation(formattedHierarchicalRelation, eligibleDatasetElementId, editorStore) {
      this.eligibleChildren = this.eligibleChildren.filter((child) => child.id !== eligibleDatasetElementId);
      this.children.push(formattedHierarchicalRelation);

      const dataStore = useDataStore();
      dataStore.updateElementChildren(this.children, editorStore.datasetElementId);
      await dataStore.addFormulasResultsToElements();
      return true; // Some children were created
    },
    async createRelationFromNewElement({ attributes, dataTypeId }) {
      if (attributes && dataTypeId) {
        const editorStore = useEditorStore();
        const id = editorStore.datasetElementId;
        const requestBody = {
          attributes,
          dataTypeId
        };
        const REQUEST_URL = `/dataset-elements/${id}/children`;
        const newDatasetElement = await post(REQUEST_URL, requestBody);
        if (newDatasetElement) {
          const { id, hierarchicalRelationId } = newDatasetElement;
          const datasetElement = await get(`/dataset-elements/${id}`);
          const usersStore = useUsersStore();
          const attributes = {
            ...datasetElement.attributes,
            owner: usersStore.currentUser.id
          };
          const updatedDatasetElement = await put(`/dataset-elements/${datasetElement.id}`, { attributes });
          const relation = editorStore.getRelationObject(updatedDatasetElement);
          const addedDatasetElement = { ...relation, hierarchicalRelationId };
          this.children.push({ ...relation, hierarchicalRelationId });
          const dataStore = useDataStore();
          dataStore.updateElementChildren(this.children, editorStore.datasetElementId);
          await dataStore.addFormulasResultsToElements();
          return addedDatasetElement;
        }
      }
    },
    async fetchDatasetElementDependencies() {
      const editorStore = useEditorStore();
      const dataStore = useDataStore();
      const appStore = useAppStore();
      const datasetElementId = editorStore.datasetElementId;

      const response = await get(`/dataset-elements/${datasetElementId}/relations`);
      if (!response?.data) {
        return;
      }

      const createdDependencies = response.data;
      const newDatasetElements = createdDependencies
        .map(({ relatedDatasetElementId }) => !dataStore.datasetElements[relatedDatasetElementId] && relatedDatasetElementId)
        .filter(Boolean);

      let relatedDatasetElements = [];
      if (newDatasetElements.length) {
        const query = newDatasetElements.map((id) => `ids=${id}`).join('&');
        const datasetElementsInfo = await get(`/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`);
        const datasetElementsData = datasetElementsInfo?.data ?
          await apiUtils.getNextPage(datasetElementsInfo, datasetElementsInfo.data, post) : [];
        const isElementDataType = (el) => !el.typeName.includes('/boardget-data-types/opex/Indicator');
        const filteredDatasetElementsData = datasetElementsData.filter(isElementDataType);
        if (filteredDatasetElementsData.length === 0) {
          return;
        }
        relatedDatasetElements = filteredDatasetElementsData.map((datasetElement) => editorStore.getRelationObject(datasetElement));
      }
      const formattedDependencies = createdDependencies.map(({id, relatedDatasetElementId}) => {
        const relatedDatasetElement = dataStore.datasetElements[relatedDatasetElementId] ?
          editorStore.getRelationObject(dataStore.datasetElements[relatedDatasetElementId]) :
          relatedDatasetElements.find((el) => el.id === relatedDatasetElementId);
        return relatedDatasetElement ? { relationId: id, ...relatedDatasetElement } : null;
      }).filter((dependency) => dependency !== null);
      this.createdDependencies = formattedDependencies;
      this.eligibleDependencies = this.eligibleDependencies.filter((eligibleDependency) =>
        !this.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
      );
      this.dependencies = apiUtils.formatDependencies(this.createdDependencies, appStore.datatypes);
    },
    async createDependencyWithNewDatasetElement({ attributes, dataTypeId }) {
      const editorStore = useEditorStore();
      const dataStore = useDataStore();
      const appStore = useAppStore();
      const datasetElementId = editorStore.datasetElementId;
      const relationTypeId = this.relationTypes.find((relationType) => relationType.name === 'Relates to').id;

      const requestBody = {
        attributes,
        dataTypeId,
        relationTypeId
      };

      const result = await post(`/dataset-elements/${datasetElementId}/relations`, requestBody);

      if (result) {
        const { relationId, id } = result;
        const relatedDatasetElementInfo = await get(`/dataset-elements/${id}`);
        const relatedDatasetElement = editorStore.getRelationObject(relatedDatasetElementInfo);
        const newDependency = { relationId, id, ...relatedDatasetElement };
        this.createdDependencies.push(newDependency);

        dataStore.updateElementDependencies(this.createdDependencies, datasetElementId);
        await dataStore.addFormulasResultsToElements();
        this.dependencies = apiUtils.formatDependencies(this.createdDependencies, appStore.datatypes);
        return relatedDatasetElement;
      }
    },
    async createDependencyWithExistingDatasetElement({ eligibleDatasetElementId }) {
      const editorStore = useEditorStore();
      const dataStore = useDataStore();
      const appStore = useAppStore();
      const datasetElementId = editorStore.datasetElementId;
      const relationTypeId = this.relationTypes.find((relationType) => relationType.name === 'Relates to').id;

      const requestBody = [{
        sourceDatasetElementId: datasetElementId,
        targetDatasetElementId: eligibleDatasetElementId,
        relationTypeId
      }];

      const creations = await post('/relations', requestBody);
      const createdDependencies = creations?.data?.filter(({ status }) => status === 200) || [];

      if (createdDependencies.length) {
        const formattedCreatedDependencies = await Promise.all(createdDependencies.map(async ({id, targetDatasetElementId}) => {
          let relatedDatasetElement;
          if (dataStore.datasetElements[targetDatasetElementId]) {
            relatedDatasetElement = editorStore.getRelationObject(dataStore.datasetElements[targetDatasetElementId]);
          } else {
            const relatedDatasetElementInfo = await get(`/dataset-elements/${targetDatasetElementId}`);
            relatedDatasetElement = editorStore.getRelationObject(relatedDatasetElementInfo);
          }
          return { relationId: id, ...relatedDatasetElement };
        }));

        this.createdDependencies = [...this.createdDependencies, ...formattedCreatedDependencies];

        this.eligibleDependencies = this.eligibleDependencies.filter((eligibleDependency) =>
          !this.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
        );
        this.dependencies = apiUtils.formatDependencies(this.createdDependencies, appStore.datatypes);
        dataStore.updateElementDependencies(this.createdDependencies, datasetElementId);
        await dataStore.addFormulasResultsToElements();
        return true;
      }
      return false;
    },
    async deleteRelations({relationId, relatedDatasetElementId, relationType = RELATION_TYPES[2]}) {
      const DELETE_RELATION_URLS = {
        [RELATION_TYPES[0]]: `/hierarchical-relations/${relationId}`,
        [RELATION_TYPES[1]]: `/hierarchical-relations/${relationId}`,
        [RELATION_TYPES[2]]: `/relations/${relationId}`
      };
      const requestUrl = DELETE_RELATION_URLS[relationType];
      const isDeleted =  await del(requestUrl);
      const dataStore = useDataStore();
      if (isDeleted) {
        const isSuccessful = dataStore.deleteSelectedDatasetElement(relatedDatasetElementId);
        if (isSuccessful) {
          await this.updateRelationsData({relatedDatasetElementId, relationType});
        }
      }
    },
    async unlinkRelationalElements({relationId, relatedDatasetElementId, relationType}) {
      let requestUrl = `/hierarchical-relations/${relationId}`;
      if (relationType === RELATION_TYPES[2]) {
        requestUrl = `/relations/${relationId}`;
      }
      const isUnlinked = await del(requestUrl);
      if (isUnlinked) {
        await this.updateRelationsData({relatedDatasetElementId, relationType});
      }
    },
    async updateRelationsData({relatedDatasetElementId, relationType = RELATION_TYPES[2]}) {
      if (relationType === RELATION_TYPES[0]) {
        this.parent = null;
        return;
      }
      if (relationType === RELATION_TYPES[1]) {
        this.children = this.children.filter((child) => child.id !== relatedDatasetElementId);
        return;
      }
      const appStore = useAppStore();
      const dataStore = useDataStore();
      const editorStore = useEditorStore();
      const datasetElementId = editorStore.datasetElementId;

      this.createdDependencies = this.createdDependencies.filter((dependency) => dependency.id !== relatedDatasetElementId);
      this.dependencies = apiUtils.formatDependencies(this.createdDependencies, appStore.datatypes);

      dataStore.updateElementDependencies(this.createdDependencies, datasetElementId);
      await dataStore.addFormulasResultsToElements();
    },
    resetHierarchicalRelations() {
      this.parent = null;
      this.children = [];
      this.eligibleChildren = [];
      this.eligibleParents = [];
      this.eligibleChildDataTypes = [];
      this.eligibleParentDataTypes = [];
      this.createdDependencies = [];
      this.dependencies = [];
      this.eligibleDependencies = [];
      this.relationTypes = [];
    }
  },
  getters: {
    getRelationFromDatasetElementIds() {
      return (hierarchicalRelations, datasetElementParentId, datasetElementChildId) =>
        Object.values(hierarchicalRelations).find((hierarchicalRelation) => hierarchicalRelation.datasetElementParentId === datasetElementParentId
          && hierarchicalRelation.datasetElementChildId === datasetElementChildId);
    }
  }
});
