import './index.css';
import './focus.scss';
import './leveling.scss';
import './media-queries.scss';
import './fonts.scss';
// import 'flowbite'; // TODO : still relevant ?

// Global imports for design-tokens
import 'iobeya-design-tokens/scss/app/iobeya-app-form-group.scss';
import 'iobeya-design-tokens/scss/app/iobeya-list-elements.scss';
import 'iobeya-design-tokens/scss/app/iobeya-textInput.scss';
import 'iobeya-design-tokens/scss/app/iobeya-icon.scss';

import DesignSystemButton from './components/Button/Button.vue';
import DesignSystemCheckButton from './components/CheckButton/CheckButton.vue';
import DesignSystemIcon from './components/Icon/Icon.vue';
import DesignSystemIconWithLabel from './components/IobIconWithLabel/IobIconWithLabel.vue';
import DesignSystemSearchSelect from './components/SearchSelect/SearchSelect.vue';
import DesignSystemTag from './components/Tag/Tag.vue';
import DesignSystemText from './components/IobText/IobText.vue';
import DesignSystemUser from './components/IobUser/IobUser.vue';
import DesignSystemUserSelector from './components/UserSelector/UserSelector.vue';
import HiddenAvatarList from './components/HiddenAvatarList/HiddenAvatarList.vue';
import OutsideClickListener from './components/OutsideClickListener/OutsideClickListener.vue';
import Badge from './components/Badge/Badge.vue';
import UserPopOver from './components/UserPopOver/UserPopOver.vue';
import DropdownButtonFlowbite from './components/DropdownButtonFlowbite/DropdownButtonFlowbite.vue';
import SearchMenu from './components/SearchMenu/SearchMenu.vue';
import DropdownButton from './components/DropdownButton/DropdownButton.vue';
import MultipleSelect from './components/MultipleSelect/MultipleSelect.vue';
import Checkbox from './components/Checkbox/Checkbox.vue';
import DialogBox from './components/DialogBox/DialogBox.vue';
import ScrollBar from './components/ScrollBar/ScrollBar.vue';
import InputWithIcon from './components/InputWithIcon/InputWithIcon.vue';
import MultiLabelField from './components/MultiLabelField/MultiLabelField.vue';
import WarningBox from './components/WarningBox/WarningBox.vue';
import Favorite from './components/Favorite/Favorite.vue';
import AvatarWithPopover from './components/AvatarWithPopOver/AvatarWithPopover.vue';
import AvatarWithName from './components/AvatarWithName/AvatarWithName.vue';
import RedoIconButton from './components/RedoIconButton/RedoIconButton.vue';
import MultiLevelMenu from './components/multiLevelMenu/MultiLevelMenu.vue';
import DropdownMenu from './components/DropdownMenu/DropdownMenu.vue';
import UndoIconButton from './components/UndoIconButton/UndoIconButton.vue';
import ColorPicker from './components/ColorPicker/ColorPicker.vue';
import IconWithLabel from './components/IconWithLabel/IconWithLabel.vue';
import Chip from './components/IobChip/IobChip.vue';
import Actions from './components/Actions/Actions.vue';
import CustomButton from './components/CustomButton/CustomButton.vue';
import LabelField from './components/LabelField/LabelField.vue';
import ColorsPopover from './components/ColorsPopover/ColorsPopover.vue';
import UserListPopOver from './components/UserListPopOver/UserListPopOver.vue';
import IobeyaFieldset from './components/IobFieldset/IobFieldset.vue';
import IconLoader from './components/IconLoader/IconLoader.vue';
import LoadingPageLayer from './components/LoadingPageLayer/LoadingPageLayer.vue';
import ColoredCircle from './components/ColoredCircle/ColoredCircle.vue';
import SimplePopup from './components/PopUp/SimplePopup.vue';
import PopUp from './components/PopUp/PopUp.vue';
import Spinner from './components/Spinner/Spinner.vue';
import CautionMark from './components/CautionMark/CautionMark.vue'
import IobNavigationMenu from './components/IobNavigationMenu/IobNavigationMenu.vue';
import IobAlert from './components/Atoms/IobAlert/IobAlert.vue';

/** Import Atoms here */
import PopOver from './components/Atoms/popover/PopOver.vue';
import IobProgressBar from './components/Atoms/IobProgressBar/IobProgressBar.vue';
import IobLoadingBar from './components/Atoms/IobLoadingBar/IobLoadingBar.vue';
import IobSeparator from './components/Atoms/IobSeparator/IobSeparator.vue';
import IobSidebar from './components/Atoms/Sidebar/IobSidebar.vue';
import IobSidebarHeader from './components/Atoms/Sidebar/SidebarHeader/IobSidebarHeader.vue';
import IobSidebarContent from './components/Atoms/Sidebar/SidebarContent/IobSidebarContent.vue';
import IobSidebarFooter from './components/Atoms/Sidebar/SidebarFooter/IobSidebarFooter.vue';
import IobIconShape from './components/Atoms/IobIconShape/IobIconShape.vue';
import IobTextarea from './components/Atoms/IobTextarea/IobTextarea.vue';
import IobBadge from './components/Atoms/IobBadge/IobBadge.vue';
import IobShapeColor from './components/Atoms/IobShapeColor/IobShapeColor.vue';
import IobeyaLogo from './components/Atoms/IobeyaLogo/IobeyaLogo.vue';
import IobResourceCenter from './components/Atoms/IobResourceCenter/IobResourceCenter.vue';
import IobSkeleton from './components/Atoms/IobSkeleton/IobSkeleton.vue';
import IobCheckbox from './components/Atoms/IobCheckbox/IobCheckbox.vue';
import IobCircleLoader from './components/Atoms/IobCirlceLoader/IobCircleLoader.vue';
import IobTextElement from './components/Atoms/IobTextElement/IobTextElement.vue';
import IobTitleElement from './components/Atoms/IobTitleElement/IobTitleElement.vue';
import IobLink from './components/Atoms/IobLink/IobLink.vue';
import IobColorPickerElement from './components/Atoms/IobColorPickerElement/IobColorPickerElement.vue';

/** Import Molecules here */

import IobMainNavHeader from './components/Molecules/IobSideBar/headerComponents/IobMainNavHeader/IobMainNavHeader.vue';
import IobLabelField from './components/Molecules/IobLabelField/IobLabelField.vue';
import IobActionButton from './components/Molecules/IobActionButton/IobActionButton.vue';
import IobButton from './components/Molecules/IobButton/IobButton.vue';
import IobAvatarWithName from './components/Molecules/IobAvatarWithName/IobAvatarWithName.vue';
import IobLoadingBarWithLabel from './components/Molecules/IobLoadingBarWithLabel/IobLoadingBarWithLabel.vue';
import IobHeaderAction from './components/Molecules/IobHeaderAction/IobHeaderAction.vue';
import IobDropdown from './components/Molecules/IobDropdown/IobDropdown.vue'
import IobHeaderNav from './components/Molecules/IobHeaderNav/IobHeaderNav.vue';
import IobAvatar from './components/Molecules/IobAvatar/IobAvatar.vue';
import IobAvatarGroup from './components/Molecules/IobAvatarGroup/IobAvatarGroup.vue';
import IobMenu from './components/Molecules/Menu/IobMenu.vue';
import IobMenuItem from './components/Molecules/Menu/MenuItem/IobMenuItem.vue';
import IobMenuDivider from './components/Molecules/Submenu/MenuDivider/IobMenuDivider.vue';
import IobSubmenu from './components/Molecules/Submenu/IobSubmenu.vue';
import IobSubmenuItem from './components/Molecules/Submenu/SubmenuItem/IobSubmenuItem.vue';
import IobDropdownButton from './components/Molecules/IobDropdownButton/IobDropdownButton.vue';
import IobBadgeDropdown from './components/Molecules/IobBadgeDropdown/IobBadgeDropdown.vue';
import IobTableHeader from './components/Molecules/IobTable/IobTableHeader/IobTableHeader.vue';
import IobTableCell from './components/Molecules/IobTable/IobTableCell/IobTableCell.vue';
import IobTableRow from './components/Molecules/IobTable/IobTableRow/IobTableRow.vue';
import IobTable from './components/Molecules/IobTable/IobTable.vue';
import IobHiddenAvatarList from './components/Molecules/IobHiddenAvatarList/IobHiddenAvatarList.vue';
import IobTableHeaderCell from './components/Molecules/IobTable/IobTableHeaderCell/IobTableHeaderCell.vue';
import IobEditor from './components/Molecules/IobEditor/IobEditor.vue';
import IobAttachmentsList from './components/Molecules/IobAttachmentsList/IobAttachmentsList.vue';
import TreeNode from './components/Molecules/IobTree/TreeNode.vue';
import IobListWithIdElement from './components/Molecules/IobListWithIdElement/IobListWithIdElement.vue';
import IobSelectBadge from './components/Molecules/IobSelectBadge/IobSelectBadge.vue';
import IobProgressBarWithLabel from './components/Molecules/IobProgressBarWithLabel/IobProgressBarWithLabel.vue';
import IobCircleLoaderWithLabel from './components/Molecules/IobCircleLoaderWithLabel/IobCircleLoaderWithLabel.vue';
import IobTableCellSkeleton from './components/Molecules/IobTable/IobTableCellSkeleton/IobTableCellSkeleton.vue';
import IobModal from './components/Molecules/IobModal/IobModal.vue';
import IobSidePanel from './components/Molecules/IobSidePanel/IobSidePanel.vue';
import IobCover from './components/Molecules/Cover/IobCover.vue';
import IobCardPreview from './components/Molecules/Card/IobCardPreview/IobCardPreview.vue';
import IobIconShapeElement from './components/Molecules/IobIconShapeElement/IobIconShapeElement.vue';
import IobImageTextElement from './components/Molecules/IobImageTextElement/IobImageTextElement.vue';
import IobDialogBox from './components/Molecules/IobDialogBox/IobDialogBox.vue';
import IobInputAvatar from './components/Molecules/IobInputAvatar/IobInputAvatar.vue';
import IobMenuElement from './components/Molecules/IobMenuElement/IobMenuElement.vue';
import IobCard from './components/Molecules/Card/IobCard/IobCard.vue';
import IobElevationButton from './components/Molecules/IobElevationButton/IobElevationButton.vue';
import IobTimeframe from './components/Molecules/IobTimeframe/IobTimeframe.vue';
import IobRoutineCard from './components/Molecules/IobRoutineCard/IobRoutineCard.vue';
import IobIconDropdown from './components/Molecules/IobIconDropdown/IobIconDropdown.vue';
import IobCarousel from './components/Molecules/IobCarousel/IobCarousel.vue';
import IobFilterModal from './components/Molecules/IobFilterModal/IobFilterModal.vue';
import IobHiddenList from './components/Molecules/IobHiddenList/IobHiddenList.vue';
import IobNotification from './components/Molecules/IobNotification/IobNotification.vue';
import IobButtonElevation from './components/Molecules/IobButtonElevation/IobButtonElevation.vue';

import IobDropdownColorPicker from './components/Molecules/IobDropdownColorPicker/IobDropdownColorPicker.vue';
/** Import Organisms here */

import IobDropdownSelectBadge from './components/Organisms/IobDropdownSelectBadge/IobDropdownSelectBadge.vue';
import IobDropdownSearchSelect from './components/Organisms/IobDropdownSearchSelect/IobDropdownSearchSelect.vue';
import IobListItem from './components/Organisms/IobListItem/IobListItem.vue';
import IobCheckListElement from './components/Organisms/IobCheckListElement/IobCheckListElement.vue';
import IobSelect from './components/Organisms/IobSelect/IobSelect.vue';
import IobMultipleSelect from './components/Organisms/IobMultipleSelect/IobMultipleSelect.vue';
import IobInputDropdown from './components/Molecules/IobInputDropdown/IobInputDropdown.vue';
import IobActionFooter from './components/Molecules/IobActionFooter/IobActionFooter.vue';
import IobImageSelection from './components/Molecules/IobImageSelection/IobImageSelection.vue';

const components = {
  IobDropdownColorPicker,
  IobColorPickerElement,
  IobNotification,
  IobFilterModal,
  IobMultipleSelect,
  IobCheckbox,
  IobProgressBarWithLabel,
  IobCircleLoaderWithLabel,
  IobCircleLoader,
  IobBadgeDropdown,
  IobDropdownButton,
  IobTextarea,
  IobBadge,
  IobeyaLogo,
  IobResourceCenter,
  IobSkeleton,
  IobSelect,
  IobCheckListElement,
  IobHiddenAvatarList,
  IobEditor,
  IobAttachmentsList,
  TreeNode,
  IobAvatarWithName,
  IobAvatarGroup,
  IobIconShape,
  IobAvatar,
  IobLoadingBarWithLabel,
  IobSeparator,
  IobLoadingBar,
  IobProgressBar,
  IobMainNavHeader,
  IobLabelField,
  IobActionButton,
  IobButton,
  IobDropdown,
  IobCover,
  IobCardPreview,
  IobCard,
  DesignSystemButton,
  DesignSystemCheckButton,
  DesignSystemIcon,
  DesignSystemIconWithLabel,
  DesignSystemSearchSelect,
  DesignSystemTag,
  DesignSystemText,
  DesignSystemUser,
  DesignSystemUserSelector,
  HiddenAvatarList,
  OutsideClickListener,
  Badge,
  UserPopOver,
  DropdownButtonFlowbite,
  SearchMenu,
  DropdownButton,
  MultipleSelect,
  Checkbox,
  DialogBox,
  ScrollBar,
  InputWithIcon,
  MultiLabelField,
  WarningBox,
  Favorite,
  AvatarWithPopover,
  AvatarWithName,
  RedoIconButton,
  MultiLevelMenu,
  DropdownMenu,
  UndoIconButton,
  ColorPicker,
  IconWithLabel,
  Chip,
  Actions,
  CustomButton,
  LabelField,
  ColorsPopover,
  UserListPopOver,
  IobeyaFieldset,
  IconLoader,
  LoadingPageLayer,
  ColoredCircle,
  SimplePopup,
  PopUp,
  PopOver,
  Spinner,
  CautionMark,
  IobHeaderNav,
  IobHeaderAction,
  IobMenu,
  IobMenuItem,
  IobMenuDivider,
  IobSubmenu,
  IobSubmenuItem,
  IobNavigationMenu,
  IobSidebar,
  IobSidebarHeader,
  IobSidebarContent,
  IobSidebarFooter,
  IobTableHeaderCell,
  IobTableHeader,
  IobTableCell,
  IobTableRow,
  IobTable,
  IobShapeColor,
  IobSelectBadge,
  IobDropdownSelectBadge,
  IobListWithIdElement,
  IobDropdownSearchSelect,
  IobTableCellSkeleton,
  IobListItem,
  IobModal,
  IobSidePanel,
  IobTextElement,
  IobTitleElement,
  IobIconShapeElement,
  IobImageTextElement,
  IobDialogBox,
  IobInputAvatar,
  IobInputDropdown,
  IobMenuElement,
  IobElevationButton,
  IobTimeframe,
  IobLink,
  IobRoutineCard,
  IobIconDropdown,
  IobAlert,
  IobCarousel,
  IobHiddenList,
  IobActionFooter,
  IobImageSelection,
  IobButtonElevation
};

export default {
  install: function (Vue) {
    console.log('Installing vue components!');
    Object.entries(components).forEach(([name, component]) => {
      Vue.component(name, component);
    });
  },
};
