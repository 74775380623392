<template>
  <div class="IobDropdownSearchSelect">
    <div @click="handleInputClick">
      <iob-label-field
        v-if="defaultField"
        has-clear-button
        :model-value="searchInput"
        :placeholder="placeholder"
        :style="inputStyle"
        :is-focused="true"
        @update:modelValue="(value) => emit('update:modelValue', value)"
      />
      <slot />
    </div>
    <outside-click-listener
      v-if="isDropdownOpen"
      @outside-click="handleClickOutsideDropdown"
    >
      <iob-dropdown
        :items="dropdownMenuItemsArray"
        :is-scrollable="isScrollable"
        :sliced-data="true"
        :style="dropdownStyle"
        @DropdownElementItem="handleDropdownMenuClick"
      >
        <div
          v-if="isItemsArrayEmpty"
          class="IobDropdownSearchSelect-altText"
        >
          {{ alternativeText }}
        </div>
        <iob-button
          v-if="showDropdownMenuButtonLabel || (searchInput && searchInput.length && defaultField)"
          class="IobDropdownSearchSelect-createButton"
          type="ghost"
          left-icon="Plus"
          :show-left-icon="true"
          size="large"
          :label="dropdownMenuButtonLabel"
          :is-focused="true"
          @click="(event) => emit('onClickButton', event)"
        />
      </iob-dropdown>
    </outside-click-listener>
  </div>
</template>

<script setup>
import IobLabelField from '../../Molecules/IobLabelField/IobLabelField.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import IobButton from '../../Molecules/IobButton/IobButton.vue';
import { defineProps, computed, defineEmits, ref } from 'vue';
import { parseIfJson } from '../../../utils';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  defaultField: {
    type: Boolean,
    default: true,
  },
  value: {
    type: String,
    default: 'Text',
  },
  placeholder: {
    type: String,
    default: 'placeholder',
  },
  searchInput: {
    type: String,
    default: '',
  },
  dropdownMenuButtonLabel: {
    type: String,
    default: 'button label',
  },
  dropdownMenuItems: {
    required: true,
    type: [Array, String],
  },
  slicedData: {
    type: Boolean,
    default: false,
  },
  isScrollable: {
    type: Boolean,
    default: false,
  },
  showDropdownMenuButtonLabel: {
    type: Boolean,
    default: false,
  },
  dropdownStyle: {
    type: String,
    default: ''
  },
  inputStyle: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['onClickDropdownSearchSelectMenu', 'onClickButton', 'update:modelValue']);
const isDropdownOpen = ref(props.defaultField);

const alternativeText = 'There are no results that match your search';

const dropdownMenuItemsArray = computed(() => {
  const data = parseIfJson(props.dropdownMenuItems);
  data.forEach((element) => {
    element.type = !element.type ? 'listwithid' : element.type;
  });
  return data;
});

const isItemsArrayEmpty = computed(
  () => 
    dropdownMenuItemsArray.value &&
    dropdownMenuItemsArray.value.filter((item) => item.type !== 'title').length === 0
);

const handleDropdownMenuClick = ({ event ,item }) => {
  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }
  emit('onClickDropdownSearchSelectMenu', { event, item });
};

const handleInputClick = (e) => {
  e.stopPropagation();
  e.preventDefault();
  isDropdownOpen.value = true;
};

const handleClickOutsideDropdown = () => {
  /** should not close dropdown when defaultField is falsy */
  if (props.defaultField) {
    return;
  }
  isDropdownOpen.value = false;
};

</script>

<style lang="scss" src="./IobDropdownSearchSelect.scss" scoped />
