<template>
  <iob-sidebar
    size="small"
    class="IobNavigationMenu"
    elevation="1"
  >
    <iob-sidebar-header>
      <iob-main-nav-header
        :has-add-button="hasAddButton"
        :add-button-label="addButtonLabel"
        :is-search-disabled="isSearchDisabled"
        :is-plus-button-disabled="isPlusButtonDisabled"
        :has-close-button="canClose"
        :warning-message="warningMessage"
        :warning-title="warningTitle"
        :should-show-warning="showWarning"
        :button-items="buttonItems"
        :button-dropdown-style="buttonDropdownStyle"
        @button-dropdown-click="handleButtonDropdownElementClick"
        @update:searchValue="($event) => emit('update:searchValue', $event)"
        @close="emit('close')"
        @warning-close="emit('closeWarning')"
      />
    </iob-sidebar-header>
    <iob-sidebar-content>
      <iob-menu>
        <iob-menu-item
          v-for="(item, idx) in props.top"
          :key="idx"
          :selected="isSelected('top', idx)"
          :text="item.label"
          :expandable="item.expandable"
          :icon-name="item.icon"
          :status="item.status"
          :interactive="item.interactive"
          @click="() => selectItem({
            section: 'top',
            menuItemIdx: idx,
            submenuIdx: -1,
            submenuItemIdx: -1
          })"
        >
          <iob-submenu
            v-for="(cat, catIdx) in item.categories"
            :key="catIdx"
            :category-name="cat.name"
            @category-select="({categoryName})=> handleCategorySelect({
              section: 'top',
              menuItemIdx: idx,
              submenuIdx: catIdx,
              submenuItemIdx: -1
            })"
          >
            <iob-submenu-item
              v-for="(submenuItem, smIdx) in cat.items"
              :key="smIdx"
              :text="submenuItem.label"
              :action-button="submenuItem.actionButton"
              :selected="isSelected('top', idx, catIdx, smIdx)"
              @click="() => selectItem({
                section: 'top',
                menuItemIdx: idx,
                submenuIdx: catIdx,
                submenuItemIdx: smIdx
              })"
            />
          </iob-submenu>
        </iob-menu-item>
      </iob-menu>
      <iob-menu-divider />
      <div class="IobSidebar-content-scrollable">
        <iob-menu>
          <iob-menu-item
            v-for="(item, idx) in props.content"
            :key="idx"
            :text="item.label"
            :icon-name="item.icon"
            :expandable="item.expandable"
            :status="item.status"
            :interactive="item.interactive"
            :selected="isSelected('content', idx)"
          >
            <iob-submenu
              v-for="(cat, catIdx) in item.categories"
              :key="catIdx"
              :category-name="cat.name"
              @category-select="({categoryName})=> handleCategorySelect({
                section: 'content',
                menuItemIdx: idx,
                submenuIdx: catIdx,
                submenuItemIdx: -1
              })"
            >
              <iob-submenu-item
                v-for="(submenuItem, smIdx) in cat.items"
                :key="smIdx"
                :text="submenuItem.label"
                :action-button="submenuItem.actionButton"
                :selected="isSelected('content', idx, catIdx, smIdx)"
                @click.stop="() => selectItem({
                  section: 'content',
                  menuItemIdx: idx,
                  submenuIdx: catIdx,
                  submenuItemIdx: smIdx
                })"
              />
            </iob-submenu>
          </iob-menu-item>
        </iob-menu>
      </div>
    </iob-sidebar-content>
    <iob-sidebar-footer v-if="hasSidebarFooter">
      <iob-menu-divider />
      <iob-menu>
        <iob-menu-item
          v-for="(item, idx) in props.footer"
          :key="idx"
          :text="item.label"
          :icon-name="item.icon"
          :status="item.status"
          :interactive="item.interactive"
          :selected="isSelected('footer', idx)"
          @click="() => selectItem({
            section: 'footer',
            menuItemIdx: idx,
            submenuIdx: -1,
            submenuItemIdx: -1
          })"
        />
      </iob-menu>
    </iob-sidebar-footer>
  </iob-sidebar>
</template>

<script setup>
import IobMainNavHeader from '../Molecules/IobSideBar/headerComponents/IobMainNavHeader/IobMainNavHeader.vue';
import IobSidebar from '../Atoms/Sidebar/IobSidebar.vue';
import IobSidebarHeader from '../Atoms/Sidebar/SidebarHeader/IobSidebarHeader.vue';
import IobSidebarContent from '../Atoms/Sidebar/SidebarContent/IobSidebarContent.vue';
import IobSidebarFooter from '../Atoms/Sidebar/SidebarFooter/IobSidebarFooter.vue';
import IobMenu from '../Molecules/Menu/IobMenu.vue';
import IobMenuItem from '../Molecules/Menu/MenuItem/IobMenuItem.vue';
import IobMenuDivider from '../Molecules/Submenu/MenuDivider/IobMenuDivider.vue';
import IobSubmenu from '../Molecules/Submenu/IobSubmenu.vue';
import IobSubmenuItem from '../Molecules/Submenu/SubmenuItem/IobSubmenuItem.vue';

import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  top: {
    type: Array,
    default: () => ([])
  },
  content: {
    type: Array,
    default: () => ([])
  },
  footer: {
    type: Array,
    default: () => ([])
  },
  selectedItem: {
    type: Object,
    default: () => ({
      section: '',
      menuItemIdx: -1,
      submenuIdx: -1,
      submenuItemIdx: -1
    })
  },
  isSearchDisabled: {
    type: Boolean,
    default: false
  },
  hasAddButton: {
    type: Boolean,
    default: true
  },
  addButtonLabel: {
    type: String,
    default: 'New'
  },
  hasSidebarFooter: {
    type: Boolean,
    default: true
  },
  isPlusButtonDisabled: {
    type: Boolean,
    default: false
  },
  canClose: {
    type: Boolean,
    default: false
  },
  warningTitle: {
    type: String,
    default: ''
  },
  warningMessage: {
    type: String,
    default: ''
  },
  showWarning: {
    type: Boolean,
    default: true
  },
  buttonItems: {
    type: Array,
    default: () => [],
  },
  buttonDropdownStyle: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['item-select', 'category-select', 'update:searchValue', 'close', 'closeWarning', 'button-dropdown-click']);

const handleCategorySelect = (event) => {
  emit('category-select', event);
}

const selectItem = ({ section, menuItemIdx, submenuIdx, submenuItemIdx }) => {
  emit('item-select', { section, menuItemIdx, submenuIdx, submenuItemIdx });
}

const handleButtonDropdownElementClick = (item) => {
  emit('button-dropdown-click', item);
}

const isSelected = (section, menuItemIdx = -1, submenuIdx = -1, submenuItemIdx = -1) => {
  if (submenuIdx === -1) {
    return props.selectedItem.section === section && props.selectedItem.menuItemIdx === menuItemIdx;
  } else {
    return props.selectedItem.section === section && props.selectedItem.menuItemIdx === menuItemIdx && props.selectedItem.submenuIdx === submenuIdx && props.selectedItem.submenuItemIdx === submenuItemIdx;
  }
}
</script>
<style lang="scss" scoped>
.IobNavigationMenu {
    height: 100%;
    border-top: none;
    border-left: none;
    border-bottom: none;
}

.IobSidebar-content-scrollable {
    overflow-y: auto;
}

</style>
