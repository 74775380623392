import {defineStore} from 'pinia';
import {del, get, post} from 'SRC/api/index.js';
import utils from './utils';
import {CONTENT_TYPES, DATA_TYPES_NAMES_SPACE, HIERARCHY} from 'SRC/globals/constants';
import formulasStatic from 'SRC/formulas.json';
import {useDataStore} from '../data/data';
import {initializeHierarchyColors} from 'SRC/views/utils';
import axios from 'axios';
import {saveAs} from 'file-saver';
import { API_HOST } from 'SRC/config/api';

export const useAppStore = defineStore('app', {
  state: () => ({
    featureToggles: {},
    errors: [],
    exceptions: [],
    relationError: null,
    datatypes: {},
    systemDatatypes: {},
    levels: {},
    levelsTree: [],
    formulas: {},
    notification: {show: false, message: ''},
    circuitOpened: false
  }),
  actions: {
    setErrors(errors) {
      this.errors = errors;
    },
    setExceptions(exceptions) {
      this.exceptions = exceptions;
    },
    setRelationError(errorObject) {
      this.relationError = errorObject;
    },
    resetRelationError() {
      this.relationError = null;
    },
    setCircuitOpened(open) {
      this.circuitOpened = open;
    },
    setNotificationDisplayed(notification) {
      this.notification = notification;
    },
    setLevels(levels) {
      this.levels = levels;
    },
    async fetchElementsDataTypes() {
      let results = {};
      results = await get('/element-data-types?page=1&size=25');
      if (!results) {
        return;
      }
      results.data.forEach((dataType) => {
        if (dataType.namespace === DATA_TYPES_NAMES_SPACE.ELEMENT) {
          this.datatypes[dataType.id] = dataType;
        } else if (dataType.namespace === DATA_TYPES_NAMES_SPACE.SYSTEM) {
          this.systemDatatypes[dataType.id] = dataType;
        }
      });
    },
    async fetchFeatureToggles() {
      const response = await get(`${API_HOST}/actuator/features`, null, true, false);
      response?.forEach((feature) => {
        this.featureToggles[feature.name] = feature.enabled;
      });
    },
    async buildHierarchyRelationsCommon(levelsIds, callback) {
      if (!levelsIds.length) {
        return;
      }
      const dataStore = useDataStore();
      const data = await dataStore.fetchRelations(levelsIds);

      const elementsById = utils.buildElementsById(data);

      const rootElement = Object.values(this.levels).find((element) => !elementsById[element.id]);
      const rootElementClone = utils.createRootElementClone(rootElement, elementsById);

      utils.buildChildren(data.datasetElementsWithChildren, elementsById)
        .reduce((elementsByIdAcc, relation) => {
          elementsByIdAcc[relation.datasetElementId] = elementsByIdAcc[relation.datasetElementId] || { id: relation.datasetElementId, children: [] };
          elementsByIdAcc[relation.datasetElementId].children.push(...relation.children.filter(Boolean));
          return elementsByIdAcc;
        }, elementsById);

      return callback(elementsById, rootElementClone);
    },
    async fetchLevelsTree() {
      const levelsIds = Object.values(this.levels).map((level) => level.id);
      const callback = (elementsById, rootElementClone) => [elementsById[rootElementClone.id]];
      const levelsTree = await this.buildHierarchyRelationsCommon(levelsIds, callback);
      if (!levelsTree) {
        return;
      }
      this.levelsTree = levelsTree;
      this.levelsTree.forEach((level) => {
        initializeHierarchyColors(level, HIERARCHY.LEVELS_COLORS);
      });
    },
    async fetchFormulas() {
      const dataTypesValues = Object.values(this.datatypes);
      if (!(dataTypesValues && dataTypesValues.length > 0)) {
        return;
      }
      // const formulasIds = utils.extractFormulasIds(dataTypesValues);
      // const formulasIdsArrayOfArrays = utils.splitArrayOfChunks(formulasIds, MAX_ELEMENTS_PER_PAGE);
      // const queries = utils.generateQueriesFromMatrix(formulasIdsArrayOfArrays);
      // const rawResults = await utils.generateGetFormulasPromisesArray(queries);
      const rawResults = formulasStatic;
      // const results = utils.formatRawResultsArray(rawResults);
      const results = rawResults.data;
      const newFormulas = utils.setFormulas(results);
      this.formulas = newFormulas;
    },
    async deleteHierarchy(ids) {
      try {
        const response = await del(`/dataset-elements?ids=${ids}`);
        if (response?.data?.length > 0) {
          response.data.forEach((el) => {
            delete this.levels[el.id];
          });
          this.levelsTree = [];
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async ImportExcelFile(formData, transformBodyFn, url = '', uploadMode = HIERARCHY.UPLOAD_MODES.NEW_HIERARCHY) {
      const result = {success: true, data: []};
      try {
        const requestUrl = `${HIERARCHY.IMPORT_URL}${url}`;
        const response = await post(requestUrl, formData, true, true, false, transformBodyFn,  CONTENT_TYPES.MULTIPART_FORM_DATA);
        if (response?.data) {
          if (uploadMode === HIERARCHY.UPLOAD_MODES.NEW_HIERARCHY) {
            await this.updateLevelsTreeWithUploadResponse(response.data);
          }
          result.data = response.data;
        }
      } catch (error) {
        console.error('Error uploading file', error);
        result.success = false;
        result.error = error.detail;
      }
      return result;
    },
    async updateLevelsTreeWithUploadResponse(data) {
      this.levels = data.reduce((acc, datasetElement) => {
        acc[datasetElement.id] = datasetElement;
        return acc;
      }, {});
      await this.fetchLevelsTree();
    },
    async updateExistingHierarchy(data) {
      const requestUrl = `${HIERARCHY.IMPORT_URL}/update`;
      const response = await post(requestUrl, data, true, true, false);
      if (response?.data) {
        await this.updateLevelsTreeWithUploadResponse(response.data);
        return response.data;
      }
      return [];
    },
    async exportFile(url, fileName, fileType, isFrontFile = false) {
      try {
        let response;
        if (isFrontFile) {
          response = (await axios.get(url, {responseType: 'blob'})).data;
        } else {
          response = await get(url, null, true, true, {isActivated: false});
        }
        const fileBlob = new Blob([response], { type: `application/${fileType}` });
        saveAs(fileBlob, fileName);
      } catch (error) {
        console.error('Error exporting file', error);
      }
    }
  },
  getters: {
    getTitleAttribute(state) {
      const datatypes = state.datatypes;
      return (dataTypeId) => datatypes[dataTypeId].attributes.find(({ originRef }) =>
        originRef.endsWith('/vms/title'));
    },
    getDataTypeByName(state) {
      const datatypes = state.datatypes;
      return (datatypeName) => Object.values(datatypes).find((datatype) => datatype.name === datatypeName);
    },
    getSystemDataTypeByName(state) {
      const systemDatatypes = state.systemDatatypes;
      return (datatypeName) => Object.values(systemDatatypes).find((datatype) => datatype.name === datatypeName);
    }
  }
});
