<template>
  <button
    :class="classList"
    :style="`background-color: ${color};`"
  />
</template>

<script>
export default {
  name: 'IobColorPickerElement',
  props: {
    state: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'default',
    },
    color: {
      type: String,
      default: 'blue-500',
    },
  },
  computed: {
    classList() {
      return `IobColorPickerElement IobColorPickerElement--${this.state}`;
    },
  },
};
</script>
<style lang="scss" scoped src="./IobColorPickerElement.scss" />
<style
  lang="scss"
  src="iobeya-design-tokens/scss/app/iobeya-board-element-color.scss"
/>
