<template>
  <div>
    <div
      class="IobIconDropdown-icon"
      @click="openDropdown"
    >
      <iob-icon-shape
        :size="size"
        :color="currentItem.color"
        :icon-name="currentItem.name"
        :kanji-image="currentItem.backgroundImg"
      />
      <icon-loader
        v-if="hasArrow"
        :name="arrowIcon"
      />
    </div>
    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <div
        v-if="toggleDropdown && listWithSelectedShighlighted.length > 1"
        class="IobIconDropdown IobIconDropdown-container level-3"
        :style="`width: ${width}px;${additionalDropdownStyle}`"
      >
        <div
          v-for="item in listWithSelectedShighlighted"
          :key="item.name"
          @click="selectIconShape(item)"
        >
          <iob-icon-shape
            :key="item.name+size+item.color+item.iconColor"
            :icon-name="item.name"
            :size="size"
            type="square"
            :color="item.color"
            :apply-color="!item.ignoreColor"
            :icon-color="item.iconColor"
            :custom-background-color="item.backgroundColor"
            :kanji-image="item.backgroundImg"
          />
        </div>
      </div>
    </outside-click-listener>
  </div>
</template>

<script setup>
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { ref, defineEmits, defineProps, watch, computed } from 'vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import { ICON_SHAPE_SIZES_MAP } from '../../../constants';
import IconLoader from '../../IconLoader/IconLoader.vue';

const props = defineProps({
  size: {
    type: String,
    default: 'xl'
  },
  iconName: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'other'
  },
  items: {
    required: true,
    type: Array
  },
  backgroundImg: {
    type: String,
    default: ''
  },
  selectedItem: {
    type: Object,
    default: () => ({})
  },
  additionalDropdownStyle: {
    type: String,
    default: ''
  },
  highlightSelectedItem: {
    type: Boolean,
    default: false
  },
  hasArrow: {
    type: Boolean,
    default: false
  }
});
const getIconDropdownSize = (size) => ICON_SHAPE_SIZES_MAP[size] || ICON_SHAPE_SIZES_MAP.default;
const iconSize = ref(getIconDropdownSize(props.size));
const width = computed(() => 5 * iconSize.value + 4 * 16 + 48);
const arrowIcon = computed(() =>
  toggleDropdown.value ? 'ChevronUp' : 'ChevronDown'
);
const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick']);
let currentItem = ref(props.selectedItem);
const listWithSelectedShighlighted = ref(props.items);
const selectIconShape = (iconShape) => {
  emits('dropdownElementClick', iconShape);
  currentItem.value = iconShape;
  toggleDropdown.value = false;
  console.log('currentItem', currentItem.value.name);
};
const openDropdown = (e) => {
  e.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
};
watch(() => props.selectedItem, (value) => {
  currentItem.value = value;
});

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};
watch(() => props.size, (newSize) => {
  iconSize.value = getIconDropdownSize(newSize);
});
watch(() => [props.items, currentItem.value], () => {
  if (!props.highlightSelectedItem || !currentItem.value) {
    listWithSelectedShighlighted.value = props.items;
    return
  }
  const updated = props.items.map(el => {
    if (el.name === currentItem.value.name) {
      return {
        ...el,
        backgroundImg: 'white',
        backgroundColor: 'rgba(120, 115, 145, 1)',
        iconColor: '#FFF',
        ignoreColor: true
      }
    }
    return el
  })
  listWithSelectedShighlighted.value = updated;
}, { immediate: true, deep: true })
</script>

<style lang='scss' src='./IobIconDropdown.scss' scoped />
