<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div
    v-if="fieldType === 'title' && fieldType.length"
    class="IobListItemAttributes-titleContainer"
  >
    <div :class="['IobListItemAttributes-title', `IobListItemAttributes-title-${size}`]">
      {{ value }}
    </div>
  </div>
  <div 
    v-if="fieldType === 'label'"
    :class="['IobListItemAttributes-label', `IobListItemAttributes-label-${size}`]"
  >
    <iob-icon-shape 
      :icon-name="value.icon"
      :color="value.color"
      v-bind="additionalProps"
      :is-clickable="false"
    />
    <span>
      {{ value.text }}
    </span>
  </div>
  <div
    v-if="fieldType === 'text' && fieldType.length"
    class="IobListItemAttributes-textContainer"
  >
    <div :class="['IobListItemAttributes-text',`IobListItemAttributes-text-${size}`]">
      {{ value }}
    </div>
  </div>
  <iob-badge
    v-if="fieldType === 'progress'"
    :size="sizeMap"
    color="secondary"
    type="outlined"
    :label="value"
  />
  <iob-badge
    v-if="fieldType === 'status'"
    :size="sizeMap"
    :color="getBadgeColor"
    :label="value"
  />
  <iob-avatar
    v-if="fieldType === 'user'"
    :deleted-user="!value ? true : false"
    :picture="value.picture"
    v-bind="additionalProps"
    :alt-text="value.altText"
  />
  <iob-badge
    v-if="fieldType === 'timeframe'"
    :size="sizeMap"
    color="secondary"
    type="soft"
    icon="CalendarRange"
    :label="value"
    label-style="margin-left: 10px"
  />
</template>
  
<script setup>
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobIconShape from '../../../Atoms/IobIconShape/IobIconShape.vue';
import IobAvatar from '../../../Molecules/IobAvatar/IobAvatar.vue';
import { defaultBadgeColors } from '../../../../constants';
import { defineProps, computed } from 'vue';

const props = defineProps({
  fieldType: {
    type: String,
    default: 'title',
  },
  value: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: ''
  },
});

const sizeMap = computed(() => {
  switch (props.size) {
  case 'medium':
    return 'list-item-medium';
  case 'large':
    return '2xl';
  default:
    return 's';
  }
});
const additionalProps = computed(() => {
  if (props.size === 'medium') {
    return {
      customDimension: 57.8,
      altTextStyle: 'font-size: 28.8px'
    }
  }
  return {
    size: 'default',
  };
});

const getBadgeColor = computed(() => {
  return defaultBadgeColors[props.value]
});

</script>
  
<style lang="scss" src="./IobListItemAttributes.scss" scoped />
  