<template>
  <div
    :class="classList"
    :style="containerStyles"
  >
    <icon-loader
      v-if="hasIcon"
      class="IobLabelField-icon"
      :name="iconName"
      color="var(--text-input-color-icon)"
      stroke="var(--text-input-color-icon)"
      :size="iconSize"
    />
    <iob-badge
      v-else-if="hasBadge"
      :color="badgeColor"
      :icon="badgeIcon"
      :size="badgeSize"
    />

    <slot />
    <div
      v-if="!text && readOnly"
    />
    <div
      v-else-if="(text && text.length) || readOnly"
      class="IobLabelField-textContainer"
    >
      <div
        :class="{
          'IobLabelField-text': true,
          'IobLabelField-text-large': size === 'large',
        }"
      >
        {{ text }}
      </div>
    </div>
    <input
      v-else-if="!readOnly || !text.length"
      ref="inputField"
      v-model="inputValue"
      :type="inputType"
      :pattern="inputPattern" 
      :step="inputStep"
      :placeholder="placeholder"
      :disabled="disabled"
      data-test="IobLabel-inputField"
      :class="{
        'IobLabelField-input': true,
        'IobLabelField-input-large': size === 'large',
      }"
      @keydown="OnKeyDownInput"
      @input="updateValue"
      @focus="handleFocus"
      @blur="handleFocus"
    >
    <span v-if="postfix">
      {{ postfix }}
    </span>
    <icon-loader
      v-if="hasError && !readOnly"
      class="IobLabelField-alertIcon"
      name="AlertCircle"
      :size="iconSize"
      :color="iconColor"
      :stroke="iconColor"
    />

    <iob-action-button
      v-if="inputValue.length && !readOnly && hasClearButton"
      class="IobLabelField-actionButton"
      icon-name="xCircleFilled"
      color="secondary"
      type="ghost"
      :size="iconSize"
      rounded
      @click="onClickActionButton"
    />
    <icon-loader
      v-if="endIconName && endIconName.length"
      :name="endIconName"
    />
  </div>
</template>

<script setup>
import {
  computed,
  defineProps,
  defineEmits,
  ref,
  onMounted,
  onUpdated,
  nextTick,
  watch,
  toRef
} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';

const emits = defineEmits(['update:modelValue', 'focus']);

const props = defineProps({
  size: {
    type: String,
    default: 'default',
  },
  placeholder: {
    type: String,
    default: 'Placeholder...',
  },
  modelValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: 'Search',
  },
  text: {
    type: String,
    default: '',
  },
  hasClearButton: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'outlined',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  inputType: {
    type: String,
    default: 'text',
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  badgeColor: {
    type: String,
    default: 'primary',
  },
  badgeIcon: {
    type: String,
    default: '',
  },
  endIconName: {
    type: String,
    default: '',
  },
  hasBadge: {
    type: Boolean,
    default: false,
  },
  postfixType: {
    type: String,
    default: '',
  },
  postfixUnitValue: {
    type: String,
    default: '',
  },
  containerStyles: {
    type: String,
    default: '',
  },
  isPositiveInt: {
    type: Boolean,
    default: false
  },
  isPositiveDouble: {
    type: Boolean,
    default: false
  },
  hasDefaultStyle: {
    type: Boolean,
    default: true
  }
});

const iconColor = ref('var(--text-area-color-icon)');
const inputValue = ref('');
const inputField = ref(null);
const focus = ref(false);
const iconSize = ref('default');
const badgeSize = ref('small');
const modelValue = toRef(props, 'modelValue');

const sizeClasses = {
  default: 'IobLabelField-default',
  medium: 'IobLabelField-medium',
  large: 'IobLabelField-large',
};

const typeClassMap = {
  outlined: 'focus-inside focus-within IobLabelField-outlined',
  ghost: 'focus-inside focus-within IobLabelField-ghost',
};

const roundedClassMap = {
  true: 'IobLabelField-rounded',
  false: '',
};

const inputTypeNumberMap = {
  isPositiveInt: /^[0-9]\d*$/,
  isPositiveDouble: /^[0-9]+(\.\d{1,4})?$/
};

const classList = computed(() => {
  let classList = `IobLabelField ${sizeClasses[props.size]} ${
    typeClassMap[props.type]
  } ${roundedClassMap[props.rounded]}`;
  if (props.readOnly && props.hasDefaultStyle) {
    classList = `IobLabelField ${typeClassMap[props.type]}-readOnly ${
      sizeClasses[props.size]
    }`;
  } else if (props.hasError) {
    classList = `IobLabelField ${sizeClasses[props.size]} ${
      typeClassMap[props.type]
    }-error ${roundedClassMap[props.rounded]} ${
      props.disabled
        ? 'IobLabelField-disabled-error IobLabelField-disabled'
        : ''
    }`;
  } else if (props.disabled && !props.readOnly) {
    classList = `IobLabelField-disabled ${classList}`;
  }
  return classList;
});

const postfix = computed(() => {
  if (!props.postfixType) return ''
  return props.postfixType === '% Percentage' ?  '%' : props.postfixUnitValue
}, {immediate: true})

const inputPattern = computed(() => {
  if (props.inputType !== 'number') return '';
  if (props.isPositiveInt) return inputTypeNumberMap.isPositiveInt;
  if (props.isPositiveDouble) return inputTypeNumberMap.isPositiveDouble;
  return '';
});

const inputStep = computed(() => {
  if (props.isPositiveInt) return 1;
  if (props.isPositiveDouble) return 0.1;
  return '';
});

const setElementsSizes = () => {
  if (props.size === 'medium') {
    iconSize.value = 'small';
    badgeSize.value = 'xs';
  } else if (props.size === 'large') {
    iconSize.value = 'large';
    badgeSize.value = 'default';
  } else {
    iconSize.value = 'default';
    badgeSize.value = 's';
  }
};

const onClickActionButton = () => {
  inputValue.value = '';
  emits('update:modelValue', '');
};

const OnKeyDownInput = (event) => {
  const code = event.charCode || event.keyCode;
  if (code == 27) {
    inputValue.value = '';
    emits('update:modelValue', '');
  }
  const decimalSeparator = whatDecimalSeparator();
  if(['.', ','].indexOf(event.key) > -1 &&  event.key !== decimalSeparator) {
    event.preventDefault();
  }
};
const whatDecimalSeparator = () => {
  let n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
}

const handleFocus = () => {
  focus.value = !focus.value;
  emits('focus', focus.value);
};

onMounted(() => {
  nextTick(() => {
    if (props.isFocused && inputField.value) {
      setTimeout(() => {
        inputField.value.focus();
      }, 0);
    }
  });
  setElementsSizes();
});

onUpdated(() => {
  setElementsSizes();
});

watch(modelValue, (newInput) => {
  inputValue.value = newInput;
}, { immediate: true });

const handleNumberInput= (value) => {
  const { isPositiveInt, isPositiveDouble, modelValue } = props;
  if (isPositiveInt && inputTypeNumberMap.isPositiveInt.test(value)) {
    return  parseInt(value);
  } 
  if (isPositiveDouble && inputTypeNumberMap.isPositiveDouble.test(value)) {
    if (value.length === 0){
      return;
    }
    return parseFloat(value);
  }
  if (value === '') {
    return '';
  }
  return modelValue;
  
}

const updateValue = (event) => {
  const { inputType } = props;
  let value = event.target.value;
  if (inputType === 'number') {
    value = handleNumberInput(value)
  }
  inputValue.value = value;
  emits('update:modelValue', value);
};

</script>

<style lang="scss" scoped src="./IobLabelField.scss" />
