<template>
  <div :class="`SummaryDetailRow grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5`">
    <span class="SummaryDetailRow-title col-span-1">{{ props.title }}</span>
    <span class="SummaryDetailRow-info col-span-2 col-span-3 col-span-4">{{ props.info }}</span>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: ''
  },
  info: {
    type: String,
    required: true,
    default: ''
  }
});
</script>

<style scoped lang="scss">
.SummaryDetailRow {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.SummaryDetailRow-title {
  color: #242230;
  font-weight: 500;
}

.SummaryDetailRow-info {
  color: #47435F;
  font-weight: 400;
}
</style>
