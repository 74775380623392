<template>
  <div
    class="IobFilterModal"
  >
    <div class="IobFilterModal-top">
      <div class="IobFilterModal-top-header">
        <div class="IobFilterModal-top-header-container">
          <span class="IobFilterModal-top-header-container-span"> {{ headerTitle }} </span>
        </div>
        <iob-button
          v-if="hasClearButton"
          size="medium"
          color="secondary"
          type="ghost"
          :label="clearButtonLabel"
          left-icon="Trash"
          left-icon-size="small"
          :show-left-icon="true"
          :disabled="isFiltersEmpty && !(customQuickFilters.length && customQuickFilters.some((filter) => filter.selected)) || isClearButtonDisabled"
          @click="cleanAllFilters"
        />
      </div>
      <iob-separator
        v-if="hasSeparators"
        :is-vertical="false"
        state="default"
        color-type="onDefault"
        color="base"
      />
      <div class="IobFilterModal-top-title">
        <span class="IobFilterModal-top-title-span"> {{ customQuickFiltersTitle }} </span>
      </div>
      <div class="IobFilterModal-top-buttonFilters">
        <iob-button
          v-for="(filter, idx) in defaultQuickFiltersComputed"
          :key="idx"
          :size="filter.size"
          :color="filter.color"
          :type="filter.type"
          :label="filter.label"
          :left-icon="filter.icon"
          :show-left-icon="true"
          :is-focused="filter.isFocused"
          @click="handleQuickFilter(filter)"
        />
        <iob-button
          v-for="(filter, idx) in customQuickFilters"
          :key="idx"
          size="medium"
          color="secondarySoft"
          type="filled"
          :label="filter.label"
          :left-icon="filter.icon"
          :selected="filter.selected"
          :disabled="filter.disabled"
          :show-left-icon="true"
          @click="emit('setFilter', idx)"
        />
      </div>
    </div>
    <iob-separator
      v-if="hasSeparators"
      :is-vertical="false"
      state="default"
      color-type="onDefault"
      color="base"
    />
    <template v-if="hasOtherFilters">
      <div class="IobFilterModal-otherFilters">
        <div class="IobFilterModal-otherFilters-title">
          <span class="IobFilterModal-otherFilters-title-span"> {{ otherFiltersTitle }} </span>
        </div>
        <iob-dropdown-button
          size="medium"
          color="primary"
          type="ghost"
          icon-name="Plus"
          :show-left-icon="true"
          :show-right-icon="false"
          :title="addFilterBtnLabel"
          :type-check="false"
          :items="itemsDropdown"
          :is-scrollable="true"
          :dropdown-style="{
            maxHeight: '500px',
            left: '-51px',
            top: '42px'
          }"
          @dropdown-element-click="(item) => handleAddFilter(item)"
        />
      </div>
      <div class="IobFilterModal-otherFilters-container">
        <div
          v-for="(filter, index) in mappedOtherFilters"
          :key="`${filter.name}-${index}`"
          class="IobFilterModal-otherFilters-container-filter"
        >
          <div class="IobFilterModal-otherFilters-container-filter-content">
            <icon-loader
              :name="filter.iconName"
              color="#47435F"
            />
            <span class="IobFilterModal-otherFilters-container-filter-content-span">
              {{ filter.label }}
            </span>
          </div>
          <iob-toggle-switch
            v-if="filter.componentType === 'checkbox'"
            :id="filter.id"
            :checked="!!filter.isChecked[0]"
            @change="(value) => handleSwitchchange({ name: filter.name, isChecked: value })"
          />
          <iob-multiple-select
            v-else
            :dropdown-items="filter.options"
            :selected-items="handleSelectItemByName(filter.name)"
            :has-dynamic-search="filter.componentType === 'listwithid'"
            size="xs"
            color="secondary"
            :is-scrollable="true"
            :max-displayed-items="filter.maxDisplayedItems"
            dropdown-style="max-height: 250px; z-index:1"
            @on-click-item="handleOnClickItem"
            @on-delete-badge="handleDeleteItem"
            @update:inputValue="(value) => emit('update:inputValue', {value, componentType: filter.componentType})"
          >
            <tree-view
              v-if="filter.treeValues"
              :tree-data="filter.options"
              :label-field="filter.treeValues.labelField"
              :status="filter.treeValues.status"
              :is-multiple-select="true"
              :show-left-icon="false"
              :show-right-icon="true"
              :is-dropdown-opened="true"
              :show-label-field="filter.treeValues.showLabelField"
              dropdown-style="width: 100%; left: 0"
              @click.stop
              @select-multiple-items="(item) => manageTriAttributes(item, filter)"
            />
            <iob-timeframe
              v-if="
                filter.componentType === 'timeframe' || filter.componentType === 'dateRange'
              "
              style="width: 100%"
              :date-picker-styles="props.datePickerStyles"
              :default-field="false"
              :toggle-timeframe="true"
              :types="timeframeTypes[filter.componentType]"
              :default-type="defaultTypes[filter.componentType]"
              :name="filter.name"
              @handle-date-change="
                (attributes) =>
                  handleOnClickItem(formatSelectedAttributes(filter, attributes))
              "
            />
          </iob-multiple-select>
          <Iob-action-button
            size="default"
            color="secondary"
            type="ghost"
            icon-name="Minus"
            @click="handleRemoveFilter(filter)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits, watch } from 'vue';
import IobButton from '../IobButton/IobButton.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobDropdownButton from '../IobDropdownButton/IobDropdownButton.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import IobMultipleSelect from '../../Organisms/IobMultipleSelect/IobMultipleSelect.vue';
import IobTimeframe from '../IobTimeframe/IobTimeframe.vue';
import IobToggleSwitch from '../../Atoms/IobToggleSwitch/IobToggleSwitch.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import TreeView from '../../Molecules/IobTree/TreeView/TreeView.vue';

const props = defineProps({
  items: {
    required: true,
    type: [Array, String],
  },
  headerTitle: {
    type: String,
    default: 'Filters'
  },
  hasSeparators: {
    type: Boolean,
    default: true,
  },
  hasOtherFilters: {
    type: Boolean,
    default: true,
  },
  defaultQuickFilters: {
    type: Array,
    default: () => [],
  },
  otherFiltersTitle: {
    type: String,
    default: 'Other filters'
  },
  addFilterBtnLabel: {
    type: String,
    default: 'Add Filter'
  },
  customQuickFilters: {
    type: Array,
    default: () => ([])
  },
  customQuickFiltersTitle: {
    type: String,
    default: 'Quick filters'
  },
  clearButtonLabel: {
    type: String,
    default: 'Clear all filters'
  },
  isClearButtonDisabled: {
    type: Boolean,
    default: false
  },
  initialOtherFilters:{
    type: Object,
    default: () => {},
  },
  initialQuickFilters:{
    type: Object,
    default: () => {},
  },
  initialSelectedItems:{
    type: Array,
    default: () => [],
  },
  hasClearButton: {
    type: Boolean,
    default: true
  },
  datePickerStyles: {
    type: String,
    default: 'transform: translateY(0px)'
  }
});
const emit = defineEmits(['setFilter', 'clear', 'update:inputValue']);
const timeframeTypes = ref({
  dataRange: [],
  timeframe: [
    { id: 0, value: 'standard' },
    { id: 1, value: 'custom' },
  ],
});
const defaultTypes = ref({ dataRange: '', timeframe: 'standard' });
const orderedItems = ref([]);
const quickFilters = ref({});
const otherFilters = ref({});
const selectedItems = ref([]);
const isFiltersEmpty = computed(() => Object.keys(combinedFilters.value).length === 0);
const combinedFilters = computed(() => {
  const allKeys = [
    ...Object.keys(otherFilters.value),
    ...Object.keys(quickFilters.value),
  ];
  if (!allKeys.length) return {};
  const filters = allKeys.reduce((acc, key) => {
    let values = [];
    if (otherFilters.value[key] && quickFilters.value[key]) {
      values = [...new Set([...otherFilters.value[key], ...quickFilters.value[key]])];
    } else {
      values = otherFilters.value[key] || quickFilters.value[key];
    }
    if (!values) return { ...acc };
    const formattedData = values && values.length ? Object.values(values) : values;
    return { ...acc, [key]: formattedData };
  }, {});
  return filters;
});

const itemsDropdown = computed(() => {
  if (props.items && props.items.length) {
    return props.items
      .filter((item) => !Object.keys(otherFilters.value).some((key) => key === item.name))
      .map((item) => ({ ...item, text: item.label }));
  }
  return [];
});

const mappedOtherFilters = computed(() => {
  if (!Object.keys(otherFilters.value).length || !props.items.length) return [];
  const filteredData = props.items.reduce((acc, item) => {
    const itemExists = Object.keys(otherFilters.value).some((key) => key === item.name);
    if (itemExists) {
      if (item.treeValues){
        item = uncheckElement(item);
      }
      const value = otherFilters.value[item.name];
      acc[item.name] = { ...item, value };
    }
    return acc;
  }, {});
  return orderItem(filteredData);
});

watch(
  props,
  (newProp) => {
    setFilters(newProp);
  }
);
watch(
  combinedFilters,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      emit('setFilter', newVal);
    }
  },
  { deep: true }
);

const setFilters = (props) => {
  const {
    initialOtherFilters = {},
    initialSelectedItems = [],
    initialQuickFilters = {}
  } = props || {};

  otherFilters.value = {...otherFilters.value, ...initialOtherFilters};  
  selectedItems.value = [...selectedItems.value, ...initialSelectedItems];
  if (!Object.keys(initialQuickFilters).length) return;
  quickFilters.value = initialQuickFilters;
  Object.keys(initialQuickFilters).forEach((key) => {
    defaultQuickFilters.value = defaultQuickFilters.value.map((item) => {
      if (item.name === key) {
        return { ...item, isFocused: true };
      }
      return item;
    });
  });
  orderedItems.value = Object.keys(otherFilters.value);
};

const orderItem = (filteredData) => {
  return orderedItems.value.reduce((acc, key) => {
    if (filteredData[key]) {
      return { ...acc, [key]: filteredData[key] };
    }
    return acc;
  }, {});
};

const uncheckElement = (item) => {
  const { options } = item;
  if (!options) return;
  const updatedOptions = options.map((option) => {
    const selected = selectedItems.value.find((selectedItem) => selectedItem.id === option.id);
    const newOption = {
      ...option,
      checked: !!selected
    };
    if (option.children) {
      newOption.children = uncheckElement({ options: option.children }).options;
    }
    return newOption;
  });
  return { ...item, options: updatedOptions };
};

const handleSwitchchange =(item) => {
  otherFilters.value = { ...otherFilters.value, [item.name]: [item.isChecked]};  
};
const formatSelectedAttributes = (filter, attributes) => {
  const { name } = filter;
  const { label, date } = attributes;
  return { name, text:label, date, type: 'menu'};
};

const manageTriAttributes= ({selectedItems}, filter) => {
  const { name, treeValues } = filter;
  const selectedItem = selectedItems[selectedItems.length - 1];
  const text = selectedItem.attributes[treeValues.labelField];
  let newItem = { name, id: selectedItem.id, text, checked: selectedItem.checked, type: 'menu'};
  if(selectedItem.checked) {
    handleOnClickItem(newItem);
  }
  else {
    handleDeleteItem(newItem);
  }
};

const filterSelectedValues = (name) => {
  const filteredValues = handleSelectItemByName(name);
  return filteredValues.map((value) => value.withID ? value.id : (value.date || value.text));
};
const handleSelectItemByName = (name) => {
  return selectedItems.value.filter((item) => item.name === name);
};
const handleAddFilter = (item) => {
  const newItem = props.items.find((filter) => filter.name === item.name);
  if (!newItem) return;
  orderedItems.value[orderedItems.value.length] = item.name;
  otherFilters.value = { ...otherFilters.value, [newItem.name]: '' };
};
const handleRemoveFilter = (item) => {
  delete otherFilters.value[item.name];
  orderedItems.value = orderedItems.value.filter((name) => name !== item.name);
  selectedItems.value = selectedItems.value.filter(
    (selectedItem) => selectedItem.name !== item.name
  );
};

const defaultQuickFilters = ref(props.defaultQuickFilters);
const defaultQuickFiltersComputed = computed(() => defaultQuickFilters.value);
const handleFocus = (name) => {
  defaultQuickFilters.value = defaultQuickFilters.value.map((item) => {
    if (item.name === name) {
      return { ...item, isFocused: !item.isFocused };
    }
    return item;
  });
};
const handleQuickFilter = (item) => {
  let itemUpdated = {...item}; 

  if (!itemUpdated.isFocused) {
    quickFilters.value = {
      ...quickFilters.value,
      [itemUpdated.name]: itemUpdated.dates  || itemUpdated.values || [itemUpdated.id],
    };
    handleFocus(itemUpdated.name);
  } else {
    const mapQuickFilters = Object.keys(quickFilters.value).filter(
      (el) => itemUpdated.name !== el
    );
    quickFilters.value = mapQuickFilters.reduce((acc, el) => {
      return { ...acc, [el]: quickFilters.value[el] };
    }, {});
    handleFocus(itemUpdated.name);
  }
};
const handleOnClickItem = (item) => {
  selectedItems.value = [...selectedItems.value, item];
  const filteredValues = filterSelectedValues(item.name);
  otherFilters.value = { ...otherFilters.value, [item.name]: filteredValues };
};

const handleDeleteItem = (item) => {
  selectedItems.value = selectedItems.value.filter((selectedItem) => {
    if (selectedItem.name === item.name) {
      return selectedItem.text !== item.text;
    }
    return true;
  });
  const filteredValues = filterSelectedValues(item.name);
  if (!filteredValues.length) {
    delete otherFilters.value[item.name];
    return;
  }
  otherFilters.value[item.name] = filteredValues;
};

const cleanAllFilters = () => {
  otherFilters.value = {};
  quickFilters.value = {};
  selectedItems.value = [];
  orderedItems.value = [];
  defaultQuickFilters.value.map((item) => {
    item.isFocused = false;
    return item;
  });

  emit('clear');
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-template-filter.scss" />
<style lang="scss" src="./IobFilterModal.scss" scoped />
