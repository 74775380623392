<template>
  <div class="IobEditorRelations">
    <div
      :id="parentSectionId"
      class="IobEditorRelations-parent"
    >
      <span
        v-show="parent || isAddParentVisible"
        class="IobEditorRelations-title"
      >
        {{ `Parent ${dataTypeName}` }}
      </span>
      <iob-list-item
        v-if="parent"
        :id="`parent-${parent.id}`"
        :left-items="getRelationItemSpecificConfig(parent, relationConfigAttributes, relationLeftAttrsMap)"
        :right-items="getRelationItemSpecificConfig(parent, relationConfigAttributes, relationRightAttrsMap)"
        :left-icon-name="getIcon(dataTypeName, relationTypes[0]).icon"
        @on-click-details-icon="displayDetailsMenu(parent.id, parent.hierarchicalRelationId, relationTypes[0])"
        @click="emits('onClickDetailsItem',{id: parent.id})"
      />
    </div>
    <div
      :id="childrenSectionId"
      class="IobEditorRelations-children"
    >
      <div
        v-if="(children && children.length) || isAddChildrenVisible"
        class="IobEditorRelations-header"
      >
        <span class="IobEditorRelations-title">
          {{ `Child-${dataTypeName}s・${children.length}` }}
        </span>
        <icon-loader
          v-if="children && children.length"
          :id="addChildrenIconId"
          name="Plus"
          color="var(--gray-500, #787391)"
          @click="handleAddRelationViaIcon(dataTypeName, RELATION_TYPES[1])"
        />
      </div>
      <iob-list-item
        v-for="(child, i) in children"
        :id="`children-${child.id}`"
        :key="i"
        :left-items="getRelationItemSpecificConfig(child, relationConfigAttributes, relationLeftAttrsMap)"
        :right-items="getRelationItemSpecificConfig(child, relationConfigAttributes, relationRightAttrsMap)"
        :left-icon-name="getIcon(dataTypeName, relationTypes[1]).icon"
        @onClickDetailsIcon="displayDetailsMenu(child.id, child.hierarchicalRelationId, relationTypes[1])"
        @click="emits('onClickDetailsItem',{id: child.id})"
      />
    </div>
    <div 
      v-for="(datatype, index) in Object.keys(dependencies)"
      :id="`dependencies-${datatype}`"
      :key="index"
      class="IobEditorRelations-dependencies"
    >
      <div
        v-if="(dependencies[datatype] && dependencies[datatype].length) || isAddDependenciesVisible"
        class="IobEditorRelations-header"
      >
        <span class="IobEditorRelations-title">
          {{ `${datatype}・${dependencies[datatype].length}` }}
        </span>
        <icon-loader
          v-if="(dependencies[datatype] && dependencies[datatype].length)"
          :id="`add-icon-${datatype}`"
          name="Plus"
          color="var(--gray-500, #787391)"
          @click="handleAddRelationViaIcon(datatype, RELATION_TYPES[2])"
        />
      </div>
      <iob-list-item
        v-for="(element, i) in dependencies[datatype]"
        :id="`dependency-${element.dependency.id}`"
        :key="i"
        :left-items="getRelationItemSpecificConfig(element.dependency, element.relationConfigAttrs, relationLeftAttrsMap)"
        :right-items="getRelationItemSpecificConfig(element.dependency, element.relationConfigAttrs, relationRightAttrsMap)"
        :left-icon-name="getIcon(datatype, relationTypes[1]).icon"
        @onClickDetailsIcon="displayDetailsMenu(element.dependency.id, element.dependency.relationId, relationTypes[2])"
        @click="emits('onClickDetailsItem',{id: element.dependency.id, typeId:element.dependency.typeId})"
      />
    </div>
    <div
      v-if="!dependencies[selectedDataTypeName] && isAddDependenciesVisible"
      :id="`dependencies-${selectedDataTypeName}`"
      style="display: contents;"
    >
      <div class="IobEditorRelations-header">
        <span class="IobEditorRelations-title">
          {{ `Add ${selectedDataTypeName}` }}
        </span>
      </div>
    </div>
    <div
      v-if="isAddChildrenVisible || isAddParentVisible || isAddDependenciesVisible"
      ref="addRelationsSection"
    >
      <iob-editor-add-relations 
        :id="id"
        :key="reloadAddRelationsKey"
        :search-value="searchValue"
        :data-type-name="dataTypeName"
        :selected-data-type-types="selectedDataTypeTypes"
        :selected-type="selectedType"
        :selected-data-type-name="selectedDataTypeName"
        :eligible-children="eligibleChildren"
        :eligible-parents="eligibleParents"
        :eligible-dependencies="eligibleDependencies"
        :is-add-children-visible="isAddChildrenVisible"
        :is-add-parent-visible="isAddParentVisible"
        @onClickOutsideAddRelations="handleClickOutsideAddRelations"
        @onClickTypeOption="(data) => emits('onClickTypeOption', data)"
        @onClickCreateAsNewTypeName="handleCreateAsNewTypeName"
        @onClickAddRelationWithExistingDatasetElement="handleAddRelationWithExistingDatasetElement"
        @update:modelValue="handleSearch"
      />
    </div>
    <outside-click-listener
      @outside-click="handleClickOutisdeDetailsMenu"
    >
      <div ref="detailsDropdownRef">
        <iob-dropdown
          v-if="isDetailsMenuVisible"
          style="width: 191px; margin-left: auto;"
          :items="detailsMenuItems"
          @dropdown-element-item="handleOptionClick"
        />
      </div>
    </outside-click-listener>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch, nextTick } from 'vue';
import { RELATION_TYPES, RELATION_LEFT_ATTRIBUTES_MAP, RELATION_RIGHT_ATTRIBUTES_MAP, RELATION_DETAILS_MENU_ITEMS } from '../../../../constants';
import IconLoader from '../../../IconLoader/IconLoader.vue';
import IobListItem from '../../../Organisms/IobListItem/IobListItem.vue';
import IobEditorAddRelations from './IobEditorAddRelations/IobEditorAddRelations.vue';
import IobDropdown from '../../../Molecules/IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../../OutsideClickListener/OutsideClickListener.vue';

import { generateUniqueId } from '../../../../utils';
import { handleCheck } from './editor-utils-relations';

/** Data */

const relationTypes = ref(RELATION_TYPES);
const addChildrenIconId = ref(generateUniqueId());
const addRelationsSection = ref(null);
const childrenSectionId = ref(generateUniqueId());
const parentSectionId = ref(generateUniqueId());
const relationLeftAttrsMap = ref(RELATION_LEFT_ATTRIBUTES_MAP);
const relationRightAttrsMap = ref(RELATION_RIGHT_ATTRIBUTES_MAP);
const searchValue = ref('');
const detailsDropdownRef = ref(null);
const detailsMenuItems = ref(RELATION_DETAILS_MENU_ITEMS);
const isDetailsMenuVisible = ref(false);
const selectedRelationItem = ref(null);
const reloadAddRelationsKey = ref(0);

/** Props */

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  reloadKey: {
    type: Number,
    default: 0,
  },
  dataTypeId: {
    type: String,
    required: true,
  },
  dataTypeName: {
    type: String,
    default: '',
  },
  selectedDataTypeTypes: {
    type: Array,
    default: () => [],
  },
  eligibleChildren: {
    type: [Array, String],
    default: () => [],
  },
  eligibleParents: {
    type: [Array, String],
    default: () => [],
  },
  eligibleDependencies: {
    type: [Array, String],
    default: () => [],
  },
  children: {
    type: [Array, String],
    default: () => [],
  },
  parent: {
    type: [Object, String],
    default: () => ({})
  },
  dependencies: {
    type: [Object, String],
    default: () => ({})
  },
  relationConfigAttributes: {
    type: [Array, String],
    default: () => []
  },
  selectedType: {
    type: String, //yes
    default: null,
  },
  getIcon: {
    type: Function,
    default: () => ({})
  },
  isAddParentVisible: {
    type: Boolean,
    default: false,
  },
  isAddChildrenVisible: {
    type: Boolean,
    default: false,
  },
  isAddDependenciesVisible: {
    type: Boolean,
    default: false,
  },
  selectedDataTypeName: {
    type: String,
    default: null
  }
});

/** Emits */

const emits = defineEmits([
  'onClickCreateAsNewTypeName',
  'onClickAddRelationWithExistingDatasetElement',
  'onClickTypeOption',
  'onClickOutsideAddRelations',
  'onClickDetailsMenu',
  'onClickDetailsIcon'
]);

/** Computed */

watch(() => props.reloadKey, (newVal) => {
  if (newVal) {
    reloadAddRelationsKey.value++;
  }
});

watch([() => props.isAddDependenciesVisible, () => props.selectedDataTypeName],
  async ([newDependenyValue, newSelectedDataTypeName]) => {
    await nextTick();
    const isDataTypeChanged = newSelectedDataTypeName && props.isAddDependenciesVisible;
    const isSectionAdded = handleCheck(newDependenyValue || isDataTypeChanged, appendAddRelationsToSectionId, `dependencies-${props.selectedDataTypeName}`);
    if (isSectionAdded) {
      searchValue.value = '';
    }
  });

watch(() => props.isAddParentVisible,
  async (newParentValue) => {
    await nextTick();
    const isSectionAdded = handleCheck(newParentValue, appendAddRelationsToSectionId, parentSectionId.value);
    if (isSectionAdded) {
      searchValue.value = '';
    }
  }
);

watch(() => props.isAddChildrenVisible,
  async (newChildValue) => {
    await nextTick();
    const isSectionAdded = handleCheck(newChildValue, appendAddRelationsToSectionId, childrenSectionId.value);
    if (isSectionAdded) {
      searchValue.value = '';
    }
  }
);

watch(() => props.dependencies[props.selectedDataTypeName], async (newValue, oldVal) => {
  await nextTick();
  const isFirstDep = (!oldVal || oldVal.length === 0) && (newValue && newValue.length);
  const isSectionAdded = handleCheck(isFirstDep, appendAddRelationsToSectionId, `dependencies-${props.selectedDataTypeName}`);
  if (isSectionAdded) {
    searchValue.value = '';
  }
});

/** Methods */

const handleClickOutsideAddRelations = (e) => {
  e.stopPropagation();
  e.preventDefault();

  const addChildrenIcon = document.getElementById(addChildrenIconId.value);
  const addDepenciesIcon = document.getElementById(`add-icon-${props.selectedDataTypeName}`);
  if ((addChildrenIcon && addChildrenIcon.contains(e.target)) || (addDepenciesIcon && addDepenciesIcon.contains(e.target))) {
    return;
  }
  emits('onClickOutsideAddRelations', e);
};

const appendAddRelationsToSectionId = async (sectionId) => {
  await nextTick();
  const section = document.getElementById(sectionId);
  if (section && addRelationsSection.value) {
    section.appendChild(addRelationsSection.value);
  }
};

const appendDetailsMenuToElementId = async (elementId) => {
  await nextTick();
  const itemElement = document.getElementById(elementId);
  if (itemElement && detailsDropdownRef.value) {
    itemElement.insertAdjacentElement('afterend', detailsDropdownRef.value);
  }
};

const checkIfAttrExistsInMapAndItemAttrs = (item, mapAttrs, attribute) => {
  return mapAttrs[attribute] && item.attributes && item.attributes[attribute];
};

const getRelationItemSpecificConfig = (item, relationConfigAttributes, mapAttrs) => {
  return relationConfigAttributes
    .filter((attribute) => checkIfAttrExistsInMapAndItemAttrs(item, mapAttrs, attribute))
    .map((attribute) => ({
      fieldType: mapAttrs[attribute],
      value: attribute === 'owner' ? {
        picture: item.ownerPicture,
        altText: item.ownerName,
      }: item.attributes[attribute],
    }));
};

const handleCreateAsNewTypeName = (data) => {
  emits('onClickCreateAsNewTypeName', data);
  searchValue.value = '';
};

const handleAddRelationWithExistingDatasetElement = (data) => {
  emits('onClickAddRelationWithExistingDatasetElement', data);
  searchValue.value = '';
};

const displayDetailsMenu = (relatedDatasetElementId, relationId, relationType) => {
  emits('onClickDetailsIcon')
  const id = `${relationType}-${relatedDatasetElementId}`;
  isDetailsMenuVisible.value = true;
  handleCheck(true, appendDetailsMenuToElementId, id);
  selectedRelationItem.value = { relatedDatasetElementId, relationId, relationType };
};

const handleOptionClick = ({item}) => {
  isDetailsMenuVisible.value = false;
  emits('onClickDetailsMenu', {...selectedRelationItem.value, action: item.id });
};

const handleClickOutisdeDetailsMenu = (e) => {
  e.stopPropagation();
  e.preventDefault();
  if (e.target && e.target.parentNode && e.target.parentNode.id === 'detailsIcon') {
    return;
  }
  isDetailsMenuVisible.value = false;
};

const handleSearch = (data) => {
  searchValue.value = data;
  emits('search', data);
};

const handleAddRelationViaIcon = (datatype, relation) => {
  reloadAddRelationsKey.value += 1;
  emits('onClickAddRelationsIcon', {dataTypeName: datatype, relation});
};
</script>

<style lang="scss" src="./IobEditorRelations.scss" scoped />
