<template>
  <div class="IobDropdownColorPicker">
    <div class="IobDropdownColorPicker-colorsList">
      <iob-color-picker-element
        v-for="(color, index) of colorsArray.colors"
        :key="index"
        :color="color"
        :state="color === selectedColor ? 'selected' : 'default'"
        @click="handleSelect(color)"
      />
    </div>
    <span class="IobDropdownColorPicker-span"> Your colors </span>
    <div class="IobDropdownColorPicker-colorsList">
      <color-picker
        v-if="isColorPicker"
        style="position: absolute"
        @close-picker="handleClosePicker"
      />
      <iob-color-picker-element
        v-for="(color, index) of colorsArray.customColors"
        :key="index"
        :color="color"
        :state="color === selectedColor ? 'selected' : 'default'"
        @click="handleSelect(color)"
      />
      <iob-action-button
        :icon-name="actionButtonName"
        :size="actionButtonSize"
        :color="actionButtonColor"
        :type="actionButtonType"
        :disabled="false"
        :selected="false"
        @click="openColorPicker"
      />
    </div>
  </div>
</template>

<script>
import {
  BUTTON_COLOR_TOKENS_PALETTE,
  CARD_COLOR_TOKENS_PALETTE,
  NOTE_COLOR_TOKENS_PALETTE
} from '../../../constants';
import IobColorPickerElement from '../../Atoms/IobColorPickerElement/IobColorPickerElement.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import ColorPicker from '../../ColorPicker/ColorPicker.vue';

export default {
  name: 'IobDropdownColorPicker',
  components: {
    IobColorPickerElement,
    IobActionButton,
    ColorPicker,
  },
  props: {
    cardType: {
      type: String,
      default: 'card'
    }
  },
  emits: ['select-color'],
  data() {
    return {
      actionButtonName: 'Plus',
      actionButtonSize: 'default',
      actionButtonColor: 'secondary',
      actionButtonType: 'soft',
      selectedColor: '',
      isColorPicker: false,
      typeToPaletteMap: {
        card: CARD_COLOR_TOKENS_PALETTE,
        button: BUTTON_COLOR_TOKENS_PALETTE,
        note: NOTE_COLOR_TOKENS_PALETTE
      }
    };
  },
  computed: {
    colorsArray() {
      return this.typeToPaletteMap[this.cardType]
    }
  },
  methods: {
    handleSelect(color) {
      this.selectedColor = color;
      this.$emit('select-color', this.selectedColor);
    },
    openColorPicker(e) {
      this.isColorPicker = true;
      e.stopPropagation();
    },
    closePicker() {
      this.isColorPicker = false;
    },
    handleClosePicker(value) {
      if (value) {
        this.colorsArray.customColors = [
          ...this.colorsArray.customColors,
          value
        ];
      }
      this.closePicker();
    }
  }
};
</script>
<style lang="scss" scoped src="./IobDropdownColorPicker.scss" />
<style
  lang="scss"
  src="iobeya-design-tokens/scss/app/iobeya-app-dropdown-color-picker.scss"
/>
