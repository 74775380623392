<template>
  <div
    tabindex="-1"
    class="IobIconShapeElement"
    :class="classList"
    @click="handleElementClick"
  >
    <iob-icon-shape
      :size="iconSize"
      :color="iconColor"
      :icon-name="iconName"
    />
    <div class="IobIconShapeElement-text">
      <div
        v-if="title"
        class="IobIconShapeElement-title"
        :style="titleStyle"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="IobIconShapeElement-subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  titleStyle: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: ''
  },
  iconColor: {
    type: String,
    default: 'primary'
  },
  iconSize: {
    type: String,
    default: '2xl'
  },
  isClickable: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const classList = computed(() => ([{
  'IobIconShapeElement--clickable': props.isClickable,
  'focus-outside focus-within': !props.disabled && props.isClickable,
  'IobIconShapeElement--disabled': props.disabled
}]));

const emits = defineEmits(['click']);

const handleElementClick = () => {
  if (props.isClickable) {
    emits('click');
  }
}

</script>

<style lang="scss" scoped>
.IobIconShapeElement {
  display: flex;
  padding: var(--list-elements-icon-shape-padding-default-vertical) var(--list-elements-icon-shape-padding-default-horizontal);
  align-items: center;
  gap: var(--list-elements-icon-shape-gap-horizontal);
  border-radius: var(--list-elements-icon-shape-radius-default);
  background: var(--list-elements-icon-shape-color-background-secondary-ghost-default);
  &--clickable:not(.IobIconShapeElement--disabled) {
      cursor: pointer;

      &:hover {
        background: var(--list-elements-icon-shape-color-background-secondary-ghost-hover);

        .IobIconShapeElement-title {
          color: var(--list-elements-icon-shape-color-text-secondary-ghost-title-hover);
        }

        .IobIconShapeElement-subtitle {
          color: var(--list-elements-icon-shape-color-text-secondary-ghost-subtitle-hover);
        }
      }

      &:focus-within {
        background: var(--list-elements-icon-shape-color-background-secondary-ghost-hover);

        .IobIconShapeElement-title {
          color: var(--list-elements-icon-shape-color-text-secondary-ghost-title-focus);
        }

        .IobIconShapeElement-subtitle {
          color: var(--list-elements-icon-shape-color-text-secondary-ghost-subtitle-focus);
        }
      }
    }

  &--disabled {
    cursor: auto;
    opacity: var(--list-elements-expandable-element-opacity-disabled);
    background: var(--list-elements-icon-shape-color-background-secondary-ghost-disabled);
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--list-elements-icon-shape-gap-vertical);
    flex: 1 0 0;
    align-self: stretch;
    font-family: "Noto Sans", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
  }

  &-title {
    color: var(--list-elements-icon-shape-color-text-secondary-ghost-title-default);
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.14px;
  }

  &-subtitle {
    color: var(--list-elements-icon-shape-color-text-secondary-ghost-subtitle-default);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.12px;
  }
  .IobIconShape {
    cursor: inherit;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}
</style>
